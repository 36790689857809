import React from 'react'
import moment from 'moment'

function ModelInfoBillPayment(props) {
  let s = props.status === "failed" ? "d-none" : "ml-4"
  const {t}=props
  return (
    <>
      <div className="modal showBillPaymentInfo fade" id="show" role="dialog" aria-labelledby="show" style={{display: 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLongTitle">{t('pill-info')}</h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" onClick={props.dimsssModelInfoBillPayment} >×</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="mt-4 mb-4">
                <div className="header mb-4"></div>
                <div className="row">
                  <div className="col-6 label">
                    <div className="form-group">
                      <label> {t('status')} </label>
                      <p className="lead text-capitalize">{props.status}</p>
                    </div>
                  </div>
                  <div className="col-6 label">
                    <div className="form-group">
                      <label>{t('amount')}</label>
                      <p className="lead">{parseFloat(props.totalAmount).toFixed(2)} <span className="text-defult">{t('sdg')}</span></p>
                    </div>
                  </div>
                  <div className="col-6 label">
                    <div className="form-group">
                      <label>{t('operator-name')}</label>
                      <p className="lead">{props.companyName}</p>
                    </div>
                  </div>
                  <div className="col-6 label">
                    <div className="form-group">
                      <label>{t('date')}</label>
                      <p className="lead">{moment().format('LLL')}</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-border text-defult" onClick={props.dimsssModelInfoBillPayment} data-dismiss="modal">{t('cancle')}</button>
              <button type="button" className={`btn btn-common info ${s}`} onClick={props.pushBillPayment}>{t('pay')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModelInfoBillPayment;
