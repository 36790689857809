import $ from "jquery";
let fees=0
export const CalcFees = (amount,priceDoller,checkRadio,t) => {
  $(".dollar").removeClass("d-none");
  if (checkRadio === "local") {
    if (amount < 100) {
      $(".fees").addClass("d-none");
      $(".check-price").html(`${t('payment-amount-least')}` + 100 + `${t('sdg')}`);
      $(".check-price").removeClass("d-none");
    } else if (amount > 1000) {
      // $(".check-price").removeClass("d-none");
      // $(".check-price").html("Payment limit is " + 1000 + " SDG");
    } else {
      $(".check-price").addClass("d-none");
    }
  } else if (checkRadio === "paypal") {
    if (amount < priceDoller) {
      $(".fees").addClass("d-none");
      $(".check-price").html(
        `${t('payment-amount-least')}` + priceDoller + `${t('sdg')}`
      );
      $(".check-price").removeClass("d-none");
    } else if (amount > 1000) {
      // $(".check-price").removeClass("d-none");
      // $(".check-price").html("Payment limit is " + 1000 + " SDG");
    } else {
      $(".check-price").addClass("d-none");
      if (amount / priceDoller <= 10) {
        fees=((amount / priceDoller) * 0.05 + (amount / priceDoller) * 0.37 + .05 );
        $(".fees").removeClass("d-none");
      } else if (amount / priceDoller > 10) {
        fees=((amount / priceDoller) * 0.05 + (amount / priceDoller) * 0.37 + 1 );
        $(".fees").removeClass("d-none");
      }
    }
  }
  return fees
};
