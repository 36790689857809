import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavHeader from "../../ComHeader/js/NavHeader";
import BottomFooter from "../../ComFooter/js/BottomFooter";
import LineIcon from "react-lineicons";
import { MdError } from "react-icons/md";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/main.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
const Home = () => {
  const [ipAddress, setIpAddress] = useState("");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    $("#preloader").fadeOut(); // loding befor page is load
    AOS.init(); // initial aos animation libaray
    axios
      .get("https://ipapi.co/json/") // get ip address
      .then((res) => {
        setIpAddress(res.data.ip);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  const closeDialog = (e) => {
    // close dialog
    $(".modal").css({ display: "none" });
    sessionStorage.setItem("alerted", "yes");
  };
  return (
    <>
      <NavHeader active="home" />
      <section className="section-padding pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-xs-12" data-aos="fade-up">
              <Link to="/telecom">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <LineIcon
                      className="lni lni-stats-up"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    ></LineIcon>
                  </div>
                  <div className="services-content ">
                    <h4
                      className="text-muted text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {t('telecom')}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-4 col-xs-12 text-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <Link to="/electricity">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <LineIcon
                      className="lni lni-bolt"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    ></LineIcon>
                  </div>
                  <div className="services-content">
                    <h4
                      className="text-muted text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {t('electricity')}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-xs-12" data-aos="fade-up">
              <Link to="/cashout">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <LineIcon
                      className="lni lni-paypal-original"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    ></LineIcon>
                  </div>
                  <div className="services-content">
                    <h4
                      className="text-muted text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {t('paypal-cashout')}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 col-xs-12" data-aos="fade-up">
              <Link to="/cashin">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <LineIcon
                      className="lni lni-paypal-original"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    ></LineIcon>
                  </div>
                  <div className="services-content">
                    <h4
                      className="text-muted text-center"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {t('paypal-cashin')}
                    </h4>
                    <p
                      className="lead text-danger invisible text-center"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                       {t('coming-soon')}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-4 col-xs-12"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div>
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <LineIcon
                      className="lni lni-apartment"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    ></LineIcon>
                  </div>
                  <div className="services-content">
                    <h4
                      className="text-muted text-center"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      {t('government')}
                    </h4>
                    <p
                      className="lead text-danger text-center"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                     {t('coming-soon')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-xs-12"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div>
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    <LineIcon
                      className="lni lni-book"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    ></LineIcon>
                  </div>
                  <div className="services-content">
                    <h4
                      className="text-muted text-center"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      {t('education')}
                    </h4>
                    <p
                      className="lead text-danger text-center"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      {t('coming-soon')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>
      {sessionStorage.getItem("alerted") !== "yes" &&
      ipAddress === "102.126.246.135" ? (
        <div
          className="modal fade show"
          id="exampleModal"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          style={{ display: "block", top: "23%" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={(e) => closeDialog(e)}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="my-4 text-center">
                  <p className="text-success text-20 line-height-07">
                    <MdError color="#ed9323" size="4rem" />
                  </p>
                  <p className="lead alert-no">
                   {t('enable-vpn')}
                  </p>
                </div>
              </div>
              <div className="modal-footer text-center">
                <button
                  type="button"
                  className="btn btn-common"
                  data-dismiss="modal"
                  onClick={(e) => closeDialog()}
                >
                  {t('done')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
