import React from 'react'
import $ from "jquery";
import { MdError } from "react-icons/md";

function Error(props) {
  const {t} =props
  const closeDialog = (e) => {
    // close dialog
    $(".modal").css({ display: "none" });
  };
  return (
    <div
      className="modal fade show text-center"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={{ display: "block", top: "23%" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={(e) => closeDialog(e)}>
                ×
            </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="my-4 text-center">
              <p className="text-success text-20 line-height-07  text-center d-block">
                <MdError color="#ed9323" size="4rem" />
              </p>
              <p className="lead alert-no  text-center d-block">
               {t('enable-vpn')}
            </p>
            </div>
          </div>
          <div className="modal-footer text-center">
            <button
              type="button"
              className="btn btn-common"
              data-dismiss="modal"
              onClick={(e) => closeDialog()}
            >
             {t('done')}
          </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
