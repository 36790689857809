import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import {getRatePriceDoller} from '../../../../utils/GetRatePriceDoller';
import {errorMessageInfo} from '../../../../utils/ErrorMessagesTransaction';
import {useHistory} from 'react-router';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {JSEncrypt} from 'js-encrypt';
import {BASE_URL} from '../../../../utils';
import Error from '../../../ErrorComponent/Error';
import {getPublicKey} from '../../../../utils/GetPublicKey';
import {CalcFees} from '../../../../utils/CalcFees';

function ModelPayBillPayment(props) {
    const history = useHistory();
    const {t} = props;
    const [amount, setAmount] = useState(Math.abs(props.totalAmount));
    const [priceDoller, setPriceDoller] = useState();
    const [fees, setFees] = useState(0);
    var [checkRadio, setCheckRadio] = useState('paypal');
    const [uuid, setUuidV4] = useState(uuidv4());
    const [expirationDate, setExpirationDate] = useState();
    const [publickey, setPublicKey] = useState();
    const [ipin, setIPIN] = useState();
    const [cardNumber, setCardNumber] = useState();
    let [cityName, setCityName] = useState();
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [tvBtnSubmit, setTvBtnSubmit] = useState(`${t('pay-with-paypal')}`);
    useEffect(() => {
        axios
            .get('https://ipapi.co/json/') // get ip address
            .then((res) => {
                setCityName(res.data.city);
                if (cityName === 'Khartoum') {
                    setHasError(true);
                }
            })
            .catch((error) => {
            });
    }, [cityName]);
    useEffect(() => {
        setPriceDoller(getRatePriceDoller());
        setPublicKey(getPublicKey()); // get public key
        setFees(CalcFees(amount, priceDoller, checkRadio, t));
        if (amount / priceDoller <= 10) {
            setFees(CalcFees(amount, priceDoller, checkRadio, t));
            $('.fees').removeClass('d-none');
        } else if (amount / priceDoller > 10) {
            setFees(CalcFees(amount, priceDoller, checkRadio, t));
            $('.fees').removeClass('d-none');
        }
    }, [amount, fees, priceDoller, props.phoneNumber, checkRadio, errorMessage, t]);

    function sendTransaction(e) {
        e.preventDefault();
        if (checkRadio === 'paypal') {
            if (amount >= priceDoller && props.phoneNumber >= 10) {
                $('#preloader').fadeIn().removeClass('d-none');
                const data = {
                    phone_no: props.phoneNumber,
                    platform: 'web',
                    amount: amount,
                };
                axios
                    .post(
                        `${BASE_URL()}paypal/telecom/${props.payeeId}/topup/`,
                        data,
                        {
                            // send data to own server cashout
                            headers: {
                                'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
                                token: sessionStorage.getItem('token'),
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    .then((response) => {
                        localStorage.setItem('servicesName', 'Bill Payment');
                        localStorage.setItem('companyName', props.companyName);
                        localStorage.setItem('payeeId', props.payeeId);
                        window.open(response.data.approval_url, '_self');
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            // status failed  bad reguest
                            setErrorMessage(error.response.data.details);
                            $('#preloader').fadeOut();
                        } else if (error.response.status === 401) {
                            // status failed Unauthorized partiner
                            setErrorMessage(`${t('unauthorized-partiner')}`);
                            $('#preloader').fadeOut();
                        } else if (error.response.status === 404) {
                            // status failed Unauthorized token
                            sessionStorage.clear();
                            history.push({
                                pathname: '/singin',
                                state: {},
                            });
                        } else if (error.response.status === 555) {
                            // status failed order id not on Approve
                            setErrorMessage(error.response.data.details);
                            $('#preloader').fadeOut();
                        } else if (error.response.status === 502) {
                            // status failed Bad Get Way
                            setErrorMessage(`${t('bad-getway')}`);
                            $('#preloader').fadeOut();
                        } else if (error.response.status === 503) {
                            // status failed stystem maintenance
                            setErrorMessage(`${'system-maintenance'}`);
                            $('#preloader').fadeOut();
                        } else if (error.response.status === 510) {
                            // status failed ebs error
                            history.push({
                                pathname: '/wrong',
                                state: {
                                    error: errorMessageInfo(error.response.data.code, t),
                                },
                            });
                        }
                    });
            } else {
            }
        } else if (checkRadio === 'local') {
            if (amount >= 100 && props.phoneNumber >= 10) {
                $('#preloader').fadeIn().removeClass('d-none');
                const jsEncrypt = new JSEncrypt();
                jsEncrypt.setPublicKey(publickey);
                let encrypted = jsEncrypt.encrypt(uuid + ipin);
                setIPIN(encrypted);
                const data = {
                    pan: cardNumber,
                    amount: amount,
                    ipin: encrypted,
                    expiration_date: expirationDate?.replace(/\//g, ''),
                    phone_no: props.phoneNumber,
                    uuid: uuid,
                    platform: 'web',
                };
                axios
                    .post(BASE_URL() + 'telecom/' + props.payeeId + '/topup/', data, {
                        headers: {
                            'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
                            token: sessionStorage.getItem('token'),
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            history.push({
                                // go successful page
                                pathname: '/succssful',
                                state: {
                                    detail: response.data,
                                    serviceName: 'Bill Payment',
                                    company: props.companyName,
                                    payee_id: props.payeeId,
                                    locale: 'locale',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            // status failed  ebs error
                            history.push({
                                pathname: '/wrong',
                                state: {
                                    error: errorMessageInfo(error.response.data.code, t),
                                },
                            });
                        } else if (error.response.status === 401) {
                            // status failed Unauthorized partiner
                            setErrorMessage(`${t('unauthorized-partiner')}`);
                            $('#preloader').fadeOut();
                        } else if (error.response.status == 406) {
                            history.push({
                                pathname: '/wrong',
                                state: {
                                    error: errorMessageInfo(error.response.data.response_code, t),
                                },
                            });
                        } else if (error.response.status === 404) {
                            // status failed Unauthorized token
                            sessionStorage.clear();
                            history.push({
                                pathname: '/singin',
                                state: {},
                            });
                        }
                    });
            } else {
                $('.check-price').removeClass('d-none');
                $('.check-price').html(
                    `${t('payment-amount-least')}` + 100 + `${t('sdg')}`
                );
            }
        }
    }

    return (
        <>
            <div
                className='modal showBillPaymentPush fade '
                id='show'
                role='dialog'
                aria-labelledby='show'
                style={{display: 'none'}}
            >
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h6 className='modal-title' id='exampleModalLongTitle'>
                                {t('push-pill')}
                            </h6>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                <span aria-hidden='true' onClick={props.dimmssPush}>
                  ×
                </span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={(e) => sendTransaction(e)}>
                                <div className='row'>
                                    <div className='col-md-6 label'>
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('enter-p-number')}</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={props.phoneNumber}
                                                name='phoneNumber'
                                                pattern={props.numberStart}
                                                placeholder={props.phonePlaceHolder}
                                                required
                                                maxLength='10'
                                                onInput={(e) =>
                                                    (e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    ))
                                                }
                                            />
                                            <input
                                                type='text'
                                                hidden
                                                name='payee_id'
                                                className='form-control'
                                                value={props.payeeId}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-12'></div>
                                    <div className='col-md-6 label'>
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('enter-amount-sdg')}</label>
                                            <input
                                                style={{
                                                    display: 'inline-block',
                                                    width: '100%',
                                                    marginRight: '10px',
                                                }}
                                                type='text'
                                                className='form-control'
                                                defaultValue={amount}
                                                name='amount'
                                                maxLength='8'
                                                required
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );
                                                    setAmount(e.target.value);
                                                    if (e.target.value > 0) {
                                                        setAmount(e.target.value);
                                                        setFees(
                                                            CalcFees(e.target.value, priceDoller, checkRadio, t)
                                                        );
                                                        $('.dollar').addClass('d-none');
                                                    } else {
                                                        $('.dollar').addClass('d-none');
                                                    }
                                                }}
                                            />
                                            <div>
                                                <p
                                                    style={{marginLeft: '0px'}}
                                                    className='error check-price'
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                    {checkRadio === 'local' ? (
                                        ''
                                    ) : (
                                        <div className='col-md-6 dollar label'>
                                            <label htmlFor=''>{t('amount-usd')}</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                disabled
                                                value={parseFloat(amount / priceDoller).toFixed(2)}
                                                name='payee_id'
                                            />
                                            <div>
                                                <p
                                                    style={{marginLeft: '0px'}}
                                                    className='fees'
                                                >{` ${t('fees')}  : ${fees.toFixed(2)} ${t('usd')} / ${t('total-amount')} : ${(
                                                    parseFloat(fees) + parseFloat(amount / priceDoller)
                                                ).toFixed(2)} ${t('usd')}`}</p>
                                            </div>
                                        </div>
                                    )}
                                    <>
                                        {' '}
                                        {checkRadio === 'local' && (
                                            <>
                                                <div className='col-md-6 label'>
                                                    <div className='htmForm-group'>
                                                        <label htmFor=''>{t('card-no')}</label>
                                                        <input
                                                            type='text'
                                                            name='cardNumber'
                                                            id='card_number'
                                                            className='disbaled_cc required-input form-control valid'
                                                            maxLength='23'
                                                            autoComplete='off'
                                                            placeholder='xxxx-xxxx-xxxx-xxxx'
                                                            required
                                                            onInput={(e) =>
                                                                (e.target.value = e.target.value.replace(
                                                                    /[^0-9]/g,
                                                                    ''
                                                                ))
                                                            }
                                                            onChange={(e) => {
                                                                var foo = e.target.value.split('-').join('');
                                                                if (foo.length > 0) {
                                                                    foo = foo
                                                                        .match(new RegExp('.{1,4}', 'g'))
                                                                        .join('-');
                                                                }
                                                                setCardNumber(e.target.value);
                                                                e.target.value = foo;
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 label'>
                                                    <div className='form-group'>
                                                        <label for=''>{t('expiry-date')}</label>
                                                        <input
                                                            type='tel'
                                                            className='form-control'
                                                            id='date'
                                                            name='expireDate'
                                                            placeholder='MM/YY'
                                                            maxLength='5'
                                                            autoComplete='off'
                                                            required
                                                            onChange={(e) => {
                                                                e.target.value = e.target.value
                                                                    .replace(/^(\d\d)(\d)$/g, '$1/$2')
                                                                    .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                                                                    .replace(/[^\d\/]/g, '');
                                                                setExpirationDate(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 label'>
                                                    <div className='form-group'>
                                                        <label for=''>IPIN</label>
                                                        <input
                                                            type='password'
                                                            className='form-control'
                                                            id=''
                                                            name='ipin'
                                                            maxLength='5'
                                                            autoComplete='off'
                                                            placeholder=''
                                                            required
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(
                                                                    /[^0-9]/g,
                                                                    ''
                                                                );
                                                                setIPIN(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                    <div className='col-12 label'>
                                        <input
                                            className='ml-2'
                                            type='radio'
                                            name='type-payment'
                                            value='local card'
                                            onClick={() => setTvBtnSubmit(`${t('pay-with-local')}`)}
                                            onChange={() => setCheckRadio('local')}
                                        />
                                        <label className='ml-1'> {t('local-card')} </label>
                                        <input
                                            className='ml-4'
                                            type='radio'
                                            defaultChecked
                                            name='type-payment'
                                            onClick={() => setTvBtnSubmit(`${t('pay-with-paypal')}`)}
                                            value='paypal'
                                            onChange={() => setCheckRadio('paypal')}
                                        />{' '}
                                        <label className='ml-1'>{t('paypal')} </label>
                                    </div>
                                    <div className='error col-12 text-center mt-4'>
                                        {errorMessage}
                                    </div>
                                    <div className='col-md-12 pt-3'>
                                        <div className='form-group text-center'>
                                            {checkRadio && (
                                                <>

                                                        <>

                                                                <button
                                                                    type='submit'
                                                                    className='btn btn-common'
                                                                >
                                                                    {tvBtnSubmit}
                                                                </button>

                                                        </>

                                                        <Error t={t}/>

                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModelPayBillPayment;
