import axios from 'axios';
import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import {useHistory} from 'react-router';
import SucessCashIn from './CashIn/js/SucessCashIn';
import {BASE_URL} from '../utils';
import {errorMessageInfo} from '../utils/ErrorMessagesTransaction';
import {useTranslation} from 'react-i18next';

export default function WaitingProcesses() {
    const [showDialog, setShowDialog] = useState('none');
    const {t, i18n} = useTranslation();
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('lang') === 'en') {
            $('html').attr('lang', 'en');
            $('body').css({direction: 'ltr'});
        } else if (localStorage.getItem('lang') === 'ar') {
            i18n.changeLanguage('ar');
            $('body').css({direction: 'rtl'});
            $('html').attr('lang', 'ar');
        }
    }, [i18n]);
    useEffect(() => {
        let URL_Base = '';
        switch (localStorage.getItem('servicesName')) {
            case 'Electrcity':
                URL_Base = 'electricity/approve/';
                break;
            case 'PaypalCashOut':
                URL_Base = 'paypal/cashout/approve/';
                break;
            case 'Top Ups':
                URL_Base = 'telecom/approve/';
                break;
            case 'Bill Payment':
                URL_Base = 'sandbox/telecom/approve/';
                break;
            default:
        }
        var url_string = window.location.href;
        var url = new URL(url_string);
        var tokenTransaction = url.searchParams.get('token');
        const data = {
            approve_order_id: tokenTransaction,
            platform: 'web',
        };
        axios
            .post(`${BASE_URL()}${URL_Base}`, data, {
                // send data to own server cashout
                headers: {
                    'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
                    token: sessionStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (localStorage.getItem('servicesName') === 'PaypalCashOut') {
                        const detail = response.data;
                        $('#preloader').fadeOut(); // hide loading
                        if (localStorage.getItem('checkRadio') === 'account') {
                            $('#preloader').fadeOut(); // hide loading
                            setShowDialog('block');
                        } else {
                            history.push({
                                // go successful page
                                pathname: '/succssful',
                                state: {
                                    detail: detail,
                                    serviceName: 'PaypalCashOut',
                                },
                            });
                        }
                    } else {
                        history.push({
                            // go successful page
                            pathname: '/succssful',
                            state: {
                                detail: response.data,
                                serviceName: localStorage.getItem('servicesName'),
                                company: localStorage.getItem('companyName'),
                                payee_id: localStorage.getItem('payeeId'),
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                    if (error.response.status === 400) {
                        // status failed  bad reguest
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error: error.response.data.details,
                            },
                        });
                        $('#preloader').fadeOut(2000);
                    } else if (error.response.status == 406) {
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error :"we can't complete this transaction in this time please try again",
                            }
                            },
                        );
                    } else if (error.response.status === 401) {
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error: ' Unauthorized partiner',
                            },
                        });
                        // status failed Unauthorized partiner
                        $('#preloader').fadeOut(2000);
                    } else if (error.response.status === 404) {
                        // status failed Unauthorized token
                        sessionStorage.clear();
                        history.push({
                            pathname: '/singin',
                            state: {
                            error :"we can't complete this transaction in this time please try again"
                            },
                        });
                    } else if (error.response.status === 422) {
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error: ' Invild Order Id',
                            },
                        });
                    } else if (error.response.status === 555) {
                        // status failed order id not on Approve
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error: error.response.data.details,
                            },
                        });
                        $('#preloader').fadeOut(2000);
                    } else if (error.response.status === 503) {
                        // status failed stystem maintenance
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error: 'Stystem Maintenance',
                            },
                        });
                        $('#preloader').fadeOut(2000);
                    } else if (error.response.status === 510) {
                        // status failed ebs error
                        history.push({
                            pathname: '/wrong',
                            state: {
                                error: errorMessageInfo(error.response.data.code, t),
                            },
                        });
                    }
                }
            )
        ;
    }, [history]);
    return (
        <div>
            <SucessCashIn showDialog={showDialog} t={t}/>;
            <div id='preloader'>
                <div className='loader' id='loader-1'></div>
            </div>
        </div>
    );
}
