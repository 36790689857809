import axios from "axios";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { BASE_URL } from "../../../utils";
import BottomFooter from "../../ComFooter/js/BottomFooter";
import NavHeader from "../../ComHeader/js/NavHeader";
import moment from "moment";
import Pagination from "react-js-pagination";
import { MdDone, MdClear } from "react-icons/md";
import "../css/main.css";
import { useTranslation } from "react-i18next";

function History() {
  const { t, i18n } = useTranslation();
  const [historys, setHistory] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataToShow, setDataToShow] = useState([]);
  useEffect(() => {
    $("#preloader").fadeOut(3000); // loding befor page is load
    axios.get(BASE_URL() + "history/", {
          headers: {
          "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setHistory(res.data);
        setDataToShow(res.data.slice(0,15))

      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setDataToShow(historys.slice((pageNumber - 1) * 15, pageNumber * 15));
  };
  return (
    <>
      <NavHeader active="history" />
      <section id="" className="section-padding pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-xs-12">
              <div className="history">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t('services-type')}</th>
                      <th>{t('amount')}</th>
                      <th>{t('time')}</th>
                      <th>{t('status')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historys.length ? (
                      dataToShow.map((history) => {
                        return (
                          <tr key={history.id}>
                            <td>
                              <p className="font-weight-bold text-muted text-capitalize">
                                {history.tran_type}
                              </p>
                            </td>
                            <td className="text-right">
                              <p
                                className={`font-weight-bold color-success ${
                                  history.tran_amount.toString().slice(0, 1) ===
                                  "-"
                                    ? "color-error"
                                    : "color-success"
                                }`}
                              >
                                {history.tran_amount} {t('sdg')}
                              </p>
                            </td>
                            <td className="text-right">
                              <p>
                                {moment(history.tran_created).format(
                                  "D/MM/YYYY, h:mm a"
                                )}
                              </p>
                            </td>
                            <td className="text-center">
                              {history.tran_status === "Failed" ? (
                                <MdClear
                                  className={`sta tus color-white bg-wrong`}
                                />
                              ) : (
                                <MdDone
                                  className={`status color-white bg-done`}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div>{t('no-history')}</div>
                    )}
                  </tbody>
                  <nav aria-label="...">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={15}
                      totalItemsCount={historys.length}
                      pageRangeDisplayed={7}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={(e) => handlePageChange(e)}
                    />
                  </nav>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>
    </>
  );
}

export default History;
