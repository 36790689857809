import React from 'react';

function FormBillPayment(props) {
  const { t } = props;
  return (
    <>
      <form onSubmit={props.gitBill} className='label'>
        <label htmlFor=''>{t('enter-p-number')}</label>
        <input
          type='text'
          className='form-control'
          autoComplete='off'
          name='phoneNumber'
          pattern={props.numberStart}
          placeholder={props.numberPhoneHint}
          required
          maxLength='10'
          // onInvalid={e => e.target.setCustomValidity(props.messageInavlid)}
          // onValid={e => e.targe.setCustomValidity('')}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
          }}
        />
        <input
          type='text'
          className='form-control'
          hidden
          defaultValue={props.payeeId}
          name='payeeId'
        />
        <div className='col-md-12 mt-4 text-center'>
          <button className='btn btn-common submit' type='submit'>
            {t('confirm')}
          </button>
        </div>
      </form>
    </>
  );
}
export default FormBillPayment;
