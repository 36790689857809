import React, { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../css/main.css";

function BtnSendWhatsapp() {
  useEffect(() => {}, []);
  return (
    <>
      <a
        href="https://wa.me/249999947293"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className="whatsapp-icon" />
      </a>
    </>
  );
}

export default BtnSendWhatsapp;
