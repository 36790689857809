import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import NavHeader from "../../ComHeader/js/NavHeader";
import BottomFooter from "../../ComFooter/js/BottomFooter";
import $ from "jquery";
import axios from "axios";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/media.css";
import { BASE_URL } from "../../../utils";
import { useTranslation } from "react-i18next";

function RestPassword() {
  useEffect(() => {
    $("#preloader").fadeOut(); // loading befor page is load
    AOS.init(); // initial aos animation libaray
  }, []);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorPhoneNumber, setErrorPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  // send data form login to api
  const handleSubmitSingIn = (e) => {
    e.preventDefault();
    if (!phoneNumber) {
      // check phoneNumber is empty
      setErrorPhoneNumber(`${t('enter-p-number')}`);
    } else if (phoneNumber.length < 10) {
      // check phoneNumber is less than 10 characters
      setErrorPhoneNumber(`${t('p-number-must-ten-digit')}`);
    } else {
      $("#preloader").fadeIn(); // show loading
      const data = {
        user_phone: phoneNumber.slice(1).replace(/[^A-Z0-9]/gi, ""),
      };
      axios
        .post(BASE_URL() + "send/otp/", data, {
          headers: {
            "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // status success
            history.push({
              pathname: "/otp",
              state: {
                rest: "restPass",
                phone_number: phoneNumber.slice(1).replace(/[^A-Z0-9]/gi, ""),
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 333) {
            // status failed wrong number
            setErrorMessage(`${t('wrong-p-number')}`);
            $("#preloader").fadeOut();
          } else if (error.response.status === 400) {
            // status failed  bad reguest
            setErrorMessage(error.response.data.detials);
            $("#preloader").fadeOut();
          } else if (error.response.status === 401) {
            // status failed Unauthorized partiner
            setErrorMessage(`${t('unauthorized-partiner')}`);
            $("#preloader").fadeOut();
          } else if (error.response.status === 404) {
            // status failed user not registerd
            $("#preloader").fadeOut();
            setErrorMessage(`${t('p-number-not-registered')}`);
          } else if (error.response.status === 502) {
            // status failed Bad Getway
            setErrorMessage(`${t('bad-getway')}`);
            $("#preloader").fadeOut();
          } else if (error.response.status === 503) {
            // status failed stystem maintenance
            setErrorMessage(`${'system-maintenance'}`);
            $("#preloader").fadeOut();
          } else {
            $("#preloader").fadeOut();
            setErrorMessage(`${t('can’t-complete-singin')}`);
          }
        });
    }
  };
  return (
    <>
      <NavHeader active="home" />
      <section className="section-padding form-log pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xs-12">
              <div
                className="services-item form wow  fadeInRight"
                data-wow-delay="0.3s"
              >
                <div className="row justify-content-center">
                  <div className="col-md-5 bg-warning d-none d-md-block">
                    <div className="p-5 text-center">
                      <img
                        className="w-100"
                        data-aos="fade-up"
                        src="/images/Login.svg"
                        alt="images login in icon"
                      />
                      <h4
                        className="text-white f-28 mt-5"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                          {t('imagine-it')}
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className=" p-3">
                      <div className="mt-3 mb-3">
                        <p
                          className="text-right"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                         {t('don\'t-account')}
                          <Link to="/singup" className="text-warning">
                           {t('signup')}
                          </Link>
                        </p>
                      </div>
                      <div className="">
                        <h3
                         style={{fontSize:'1.3rem'}}
                        className="text-sub mb-0" data-aos="fade-up">
                          {t('reset-password')}
                        </h3>
                        <p
                          data-aos="fade-up"
                          className="invisible"
                          data-aos-delay="100"
                        >
                          {t('login-account')}
                        </p>
                      </div>
                      <form
                        className="mt-3"
                        onSubmit={(e) => handleSubmitSingIn(e)}
                      >
                        <div className="row">
                          <div
                            className="col-md-7 m-auto"
                            style={{ position: "relative", zIndex: "9999" }}
                          >
                            <div
                              className="form-group"
                              data-aos="fade-up"
                              data-aos-delay="100"
                            >
                              <label htmlFor="phone-number">
                               {t('p-number')}
                              </label>
                              <IntlTelInput
                               style={{direction:'ltr'}}
                                data-aos="fade-up"
                                data-aos-delay="100"
                                pattern="[0-9]*"
                                required
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ))
                                }
                                preferredCountries={["sd"]}
                                containerClassName="intl-tel-input"
                                inputClassName="form-control"
                                onPhoneNumberChange={(
                                  status,
                                  value,
                                  countryData,
                                  number,
                                  id
                                ) => {
                                  $(".intl-tel-input input[type='tel']").attr(
                                    "maxlength",
                                    "10"
                                  );
                                  setPhoneNumber(number);
                                  setErrorMessage("");
                                  setErrorPhoneNumber("");
                                }}
                              ></IntlTelInput>
                              <div
                                style={{ marginTop: "6px", height: "10px" }}
                                className="help-block with-errors error text-center"
                              >
                                {errorPhoneNumber}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-1 mt-md-3 text-center">
                            <div className="help-block with-errors error text-center">
                              {errorMessage}
                            </div>
                            <button
                              className="btn btn-common submit mt-2"
                              type="submit"
                            >
                              {t('send')}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>
    </>
  );
}

export default RestPassword;
