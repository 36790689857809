import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import BottomFooter from './ComFooter/js/BottomFooter'
import $ from "jquery";
import NavHeader from './ComHeader/js/NavHeader'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Wrong() {
  useEffect(() => {
    $('#preloader').fadeOut();
  }, [])
  const location = useLocation();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  if (location.state) {
    return (
      <>
      <NavHeader active="home" />
        <section id="contact" className="section-padding ">
          <div className="container">
            <div className="row  justify-content-center contact-form-area wow p-4 fadeInUp" data-wow-delay="0.3s">
              <div className="col-md-4 services-item bg-danger wrong">
                <div className="description text-center p-3">
                  <h1 className="text-white text-center">{t('wrong')}</h1>
                </div>
                <div className="icon text-center">
                  <i className="lni lni-close p-icon position-relative text-white rounded-circle"></i>
                </div>
                <p className="lead text-white mt-5 text-center">{location.state.error}</p>
                <div className="row justify-content-center mt-5">
                  <div className="col-12 text-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BottomFooter />
        <Link to="" className="back-to-top">
          <i className="material-icons">
            north
        </i>
        </Link>
        <div id="preloader">
          <div className="loader" id="loader-1"></div>
        </div>
      </>
    )
  } else {
    sessionStorage.clear()
    return (
      <Redirect to="/" />
    )
  }
}

export default Wrong
