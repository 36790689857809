import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import NavHeader from "../../ComHeader/js/NavHeader";
import BottomFooter from "../../ComFooter/js/BottomFooter";
import $ from "jquery";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/main.css";
import "../css/media.css";
import { BASE_URL } from "../../../utils";
import { useTranslation } from "react-i18next";

function Otp() {
  useEffect(() => {
    $("#preloader").fadeOut(); // loding befor page is load
    AOS.init(); // initial aos animation libaray
  }, []);
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState();
  const [num1, setNum1] = useState();
  const [num2, setNum2] = useState();
  const [num3, setNum3] = useState();
  const [num4, setNum4] = useState();
  const [num5, setNum5] = useState();
  const [num6, setNum6] = useState();
  // create ref input to use auto foucus after mouse over
  let otp1 = React.createRef();
  let otp2 = React.createRef();
  let otp3 = React.createRef();
  let otp4 = React.createRef();
  let otp5 = React.createRef();
  let otp6 = React.createRef();
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  if (location.state) {
    // send otp to api
    const handleSubmitOtp = (e) => {
      e.preventDefault();
      $("#preloader").fadeIn(); // show loading
      const data = {
        user_phone: location.state.phone_number,
        user_otp: num1 + num2 + num3 + num4 + num5 + num6,
      };
      axios
        .post(BASE_URL() + "verify/", data, {
          headers: {
            "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (location.state.rest === "restPass") {
              history.push({
                pathname: "/new-password",
                state: {
                  otp: num1 + num2 + num3 + num4 + num5 + num6,
                  phone_number: location.state.phone_number,
                },
              });
            } else {
              // status success
              sessionStorage.setItem("EnayaPay", "UserLogin");
              sessionStorage.setItem("token", res.data.token);
              history.push({
                pathname: "/home",
                state: {},
              });
            }
          } else if (res.status === 203) {
            // error messages wrong otp
            setErrorMessage(`${t('wrong-otp-number')}`);
            $("#preloader").fadeOut();
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            // status failed  bad reguest
            setErrorMessage(error.response.data.detials);
            $("#preloader").fadeOut();
          } else if (error.response.status === 404) {
            // status failed Your Api Key not valid
            setErrorMessage(`${t('api-key-not-valid')}`);
            $("#preloader").fadeOut();
          } else if (error.response.status === 503) {
            // status failed stystem maintenance
            setErrorMessage(`${'system-maintenance'}`);
            $("#preloader").fadeOut();
          } else {
            // error messages
            setErrorMessage(`${t('can’t-complete-singin')}`);
            $("#preloader").fadeOut();
          }
        });
    };
    return (
      <>
        <NavHeader active="home" />
        <section className="section-padding form-log pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-xs-12">
                <div
                  className="services-item form wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="row justify-content-center">
                    <div className="col-md-5 bg-warning d-none d-md-block">
                      <div className="p-5 text-center">
                        <img
                          className="w-100"
                          data-aos="fade-up"
                          src="/images/otp.svg"
                          alt="images otp"
                        />
                        <h4
                          className="text-white f-28 mt-5"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          {t('imagine-it')}
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className=" p-3">
                        <div className="alert " role="alert"></div>
                        <div className="enter text-center mb-3 pb-5">
                          <p className="lead mb-4">
                            {t('verify-account')}
                          </p>
                          <span className="number ">
                          {t('code-has-send')}
                            {location.state.phone_number}
                          </span>
                        </div>
                        <form
                          className="mt-3 topups"
                          onSubmit={(e) => handleSubmitOtp(e)}
                        >
                          <div className="row">
                            <div className="col">
                              <input
                                style={{ textAlign: "center", height: "50px" }}
                                data-aos="fade-right"
                                data-aos-delay="400"
                                autoFocus
                                ref={otp1}
                                id="otp1"
                                type="text"
                                name="input1"
                                className="form-control input-otp"
                                maxLength="1"
                                required
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNum1(e.target.value);
                                  setErrorMessage("");
                                  if (e.target.value.length > 0) {
                                    otp2.current.focus();
                                  }
                                }}
                              />
                            </div>
                            <div className="col">
                              <input
                                style={{ textAlign: "center", height: "50px" }}
                                data-aos="fade-right"
                                data-aos-delay="500"
                                ref={otp2}
                                id="otp-2"
                                type="text"
                                className="form-control input-otp"
                                maxLength="1"
                                required
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNum2(e.target.value);
                                  setErrorMessage("");
                                  if (e.target.value.length > 0) {
                                    otp3.current.focus();
                                  }
                                }}
                              />
                            </div>
                            <div className="col">
                              <input
                                style={{ textAlign: "center", height: "50px" }}
                                data-aos="fade-right"
                                data-aos-delay="600"
                                ref={otp3}
                                id="otp3"
                                type="text"
                                name="otp-3"
                                className="form-control input-otp"
                                maxLength="1"
                                required
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNum3(e.target.value);
                                  setErrorMessage("");
                                  if (e.target.value.length > 0) {
                                    otp4.current.focus();
                                  }
                                }}
                              />
                            </div>
                            <div className="col">
                              <input
                                style={{ textAlign: "center", height: "50px" }}
                                data-aos="fade-right"
                                data-aos-delay="700"
                                ref={otp4}
                                id="otp4"
                                type="text"
                                name="otp-4"
                                className="form-control input-otp"
                                maxLength="1"
                                required
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNum4(e.target.value);
                                  setErrorMessage("");
                                  if (e.target.value.length > 0) {
                                    otp5.current.focus();
                                  }
                                }}
                              />
                            </div>
                            <div className="col">
                              <input
                                style={{ textAlign: "center", height: "50px" }}
                                data-aos="fade-right"
                                data-aos-delay="700"
                                ref={otp5}
                                id="otp5"
                                type="text"
                                name="otp-5"
                                className="form-control input-otp"
                                maxLength="1"
                                required
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNum5(e.target.value);
                                  setErrorMessage("");
                                  if (e.target.value.length > 0) {
                                    otp6.current.focus();
                                  }
                                }}
                              />
                            </div>
                            <div className="col">
                              <input
                                style={{ textAlign: "center", height: "50px" }}
                                data-aos="fade-right"
                                data-aos-delay="700"
                                ref={otp6}
                                id="otp6"
                                type="text"
                                name="otp-6"
                                className="form-control input-otp"
                                maxLength="1"
                                required
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  setNum6(e.target.value);
                                  setErrorMessage("");
                                }}
                              />
                            </div>
                            <div className="col-md-12 mt-3 text-center">
                              <div
                                style={{ height: "10px" }}
                                className="help-block with-errors error text-center"
                              >
                                {errorMessage}
                              </div>
                              <button
                                className="btn btn-common submit mt-3"
                                data-aos="fade-up"
                                data-aos-delay="500"
                                type="submit"
                              >
                                {t('done')}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BottomFooter />
        <div id="preloader">
          <div className="loader" id="loader-1"></div>
        </div>
      </>
    );
  } else {
    sessionStorage.clear();
    return <Redirect to="/" />;
  }
}

export default Otp;
