import React, { useEffect, useState } from "react";
import BottomFooter from "./ComFooter/js/BottomFooter";
import NavHeader from "./ComHeader/js/NavHeader";
import { MdMoreVert, MdClose, MdCheck } from "react-icons/md";
import axios from "axios";
import $ from "jquery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Cards() {
  const [cards, setCards] = useState([]);
  const [title, setTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [id, setID] = useState();
  var settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMargin: 30,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 100000, settings: { slidesToShow: 3 } },
    ],
  };
  function getCrads() {
    // get all cards
    axios
      .get("https://middleware.enayapay.com/api/v2.1/cards/", {
        headers: {
          "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCards(res.data);
      });
  }
  useEffect(() => {
    $("#preloader").fadeOut(2000); // loading befor page is load
    getCrads(); // show all cards
  }, []);

  const deleteCrad = (id) => {
    // delete card
    $("#preloader").fadeIn(); // show loading
    axios
      .delete(`https://middleware.enayapay.com/api/v2.1/cards/${id}`, {
        headers: {
          "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 204) {
          $("#preloader").fadeOut(2000); // fade out loading after success
          $("#deleteModal").removeClass("show").css("display", "none");
          setTitle("Successful delete card");
          setSubTitle("Thank's Successful Delete Card");
          $("#successModal").addClass("show").css("display", "block");
          getCrads();
        }
      })
      .catch((error) => {
        $("#preloader").fadeOut(2000); // fade out loading after error
      });
  };
  const addCard = (e) => {
    // add new card
    e.preventDefault();
    $("#preloader").fadeIn(); // show loading
    const data = {
      card_pan: e.target.elements.card_number.value.split("-").join(""),
      card_expiry: e.target.elements.expireDate.value.replace(/\//g, ""),
      card_label: e.target.elements.card_label.value,
      default: 1,
    };
    axios
      .post(`https://middleware.enayapay.com/api/v2.1/cards/`, data, {
        headers: {
          "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        $("#preloader").fadeOut(2000); // fade out loading after success
        if (res.status === 201) {
          setTitle("Successful add card");
          setSubTitle("Thank's Successful Add Card");
          $("#addCardModal").removeClass("show").css("display", "none");
          $("#successModal").addClass("show").css("display", "block");
          getCrads();
        } else {
        }
      })
      .catch((error) => {
        $("#preloader").fadeOut(2000); // fade out loading after error
      });
  };
  return (
    <>
      <NavHeader />
      <div className="container mt-5 pt-5">
        <div className="col-md-12 text-right mt-4">
          <button
            className="btn btn-common submit"
            id=""
            href="#addCardModal"
            type="button"
            data-toggle="modal"
            data-target="#addCardModal"
          >
            {" "}
            Add New Card
          </button>
        </div>
        {cards.length ? (
          <Slider {...settings} loop margin={10}>
            {cards.length ? (
              cards.map((card) => {
                return (
                  <div className="mt-5 ml-3  cards" key={card.id}>
                    <div className="contact-block">
                      <div className="card-defult rounded p-3 bg-defult">
                        <div className="defult">
                          <div className="row">
                            <div className="col-7">
                              <h6 className="text-white">EnayaPay</h6>
                            </div>
                            <div className="col-5 text-end">
                              <span className="text-end text-white">
                                <a
                                  className="dropdown-toggle"
                                  href="/"
                                  role="button"
                                  id="local"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <MdMoreVert
                                    className="text-grey"
                                    aria-hidden="true"
                                  >
                                    more_vert
                                  </MdMoreVert>
                                </a>
                                <div className="dropdown">
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="local"
                                  >
                                    {/* <a className="dropdown-item" href="#">Set As Defult</a> */}
                                    {/* <a className="dropdown-item" type="button" data-toggle="modal" data-target="#addinternationalcard" href="#">Edit</a> */}
                                    <a
                                      href="#deleteModal"
                                      class=" dropdown-item"
                                      data-toggle="modal"
                                      onClick={(e) => {
                                        setID(card.id);
                                      }}
                                    >
                                      Delete
                                    </a>
                                    {/* <Link className="dropdown-item" to="#" onClick={() => deleteCrad(card.id)}>Delete</Link> */}
                                  </div>
                                </div>
                              </span>
                            </div>
                            <div className="col-12 mb-3 mt-3">
                              <p className="lead text-white">{card.card_pan}</p>
                            </div>
                            <div className="col-6 text-center">
                              <span className="d-block text-white f-bold">
                                FOLAN
                              </span>
                              <span className="d-block text-sub-white">
                                {card.card_label}
                              </span>
                            </div>
                            <div className="col-6 text-center">
                              <span className="d-block text-white f-bold">
                                Valid Thru
                              </span>
                              <span className="d-block text-sub-white">
                                {card.card_expiry.toString().slice(0, 2) +
                                  "/" +
                                  card.card_expiry.toString().slice(2, 4)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div> No cards add</div>
            )}
          </Slider>
        ) : (
          <div className="text-center"> No cards add</div>
        )}
      </div>
      <div id="deleteModal" className="modal fade">
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <div className="icon-box">
                <MdClose className="material-icons">&#xE5CD;</MdClose>
              </div>
              <h4 className="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>Delete This card</p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteCrad(id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="successModal" className="modal fade">
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <div className="icon-box success">
                <MdCheck className="material-icons success">&#xE5CD;</MdCheck>
              </div>
              <h4 className="modal-title w-100">{title}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>{subTitle}</p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary success"
                data-dismiss="modal"
                onClick={(e) => {
                  $("#successModal").removeClass("show").css("display", "none");
                  $(".modal-backdrop").removeClass("show modal-backdrop");
                }}
              >
                Thanks
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addCardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={(e) => addCard(e)}>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Add New Card
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Label Card</label>
                      <input
                        type="text"
                        maxlength="40"
                        class="form-control"
                        placeholder="Enter card lable"
                        required
                        id=""
                        name="card_label"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Card Number</label>
                      <input
                        type="text"
                        name="card_number"
                        id="card_number"
                        className="disbaled_cc required-input form-control valid"
                        maxLength="23"
                        placeholder="xxxx-xxxx-xxxx-xxxx"
                        required
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ))
                        }
                        onChange={(e) => {
                          var foo = e.target.value.split("-").join(""); // remove hyphens
                          if (foo.length > 0) {
                            foo = foo
                              .match(new RegExp(".{1,4}", "g"))
                              .join("-");
                          }
                          e.target.value = foo;
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Expiry Date</label>
                      <input
                        type="tel"
                        className="form-control"
                        id=""
                        name="expireDate"
                        placeholder="MM/YY"
                        maxLength="5"
                        required
                        onChange={(e) => {
                          e.target.value = e.target.value
                            .replace(/^(\d\d)(\d)$/g, "$1/$2")
                            .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
                            .replace(/[^\d\/]/g, "");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-border"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button class="btn btn-common" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <BottomFooter />
      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>
    </>
  );
}

export default Cards;
