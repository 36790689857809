import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import $ from "jquery";
import NavHeader from '../../ComHeader/js/NavHeader'
import BottomFooter from '../../ComFooter/js/BottomFooter';
import FormElectrcity from './FormElectrcity';
import { useTranslation } from 'react-i18next';

function Electrycity() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    $('#preloader').fadeOut(); // loding befor page is load
  }, []);
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  return (
    <>
      <NavHeader active="home" />
      <section className="section-padding pb-0">
        <div className="container">
          <div className="section-header electric text-center">
            <h2 className="section-title wow fadeInDown animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s' }}>{t('electricity')}</h2>
            <div className="shape wow fadeInDown animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationDelay: '0.3s' }}></div>
          </div>
          <div className="row">
            <div className="container">
              <div className="row form-tabs d-flex justify-content-center align-items-center">
                <div className="col-md-8 services-item">
                  <FormElectrcity t={t}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
      <Link to="" className="back-to-top">
        <i className="material-icons">
          north
        </i>
      </Link>
      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>
    </>
  )
}

export default Electrycity;
