import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Home from './Component/Home/js/Home';
import Succssful from './Component/Succssful';
import Wrong from './Component/Wrong';
import Telecom from './Component/Telecom/js/Telecom';
import PrivateRoute from './Component/Auth/js/PrivateRoute';
import PublicRoute from './Component/Auth/js/PublicRoute';
import Singup from './Component/Auth/js/Singup';
import Singin from './Component/Auth/js/Singin';
import Otp from './Component/Auth/js/Otp';
import Cards from './Component/Cards';
import CashOut from './Component/CashOut/js/CashOut';
import CashIn from './Component/CashIn/js/CashIn';
import Electrycity from './Component/Electricity/js/Electrycity';
import History from './Component/History/js/History';
import BtnSendWhatsapp from './Component/BtnWhatsapp/js/BtnSendWhatsapp';
import RestPassword from './Component/Auth/js/RestPassword';
import NewPassword from './Component/Auth/js/NewPassword';
import WaitingProcesses from './Component/WaitingProcesses';
class App extends Component {

  render() {
    return (
      <BrowserRouter>
      <BtnSendWhatsapp/>
        <Switch>
          <PublicRoute restricted={true} component={Singin} path="/" exact />
          <PublicRoute restricted={true}  component={Singin} path="/singin" exact />
          <PublicRoute restricted={true} component={Singup} path="/singup" exact />
          <PublicRoute  restricted={true} component={Otp} path="/otp" exact />
          <PublicRoute component={RestPassword} path="/reset-password" exact />
          <PublicRoute component={NewPassword} path="/new-password" exact />
          <PrivateRoute component={Home} path="/home" exact />
          <PrivateRoute component={Cards} path="/cards" exact />
          <PrivateRoute component={Telecom} path="/telecom" exact />
          <PrivateRoute component={Electrycity} path="/electricity" exact />
          <PrivateRoute component={CashOut} path="/cashout" exact />
          <PrivateRoute component={CashIn} path="/cashin" exact />
          <PrivateRoute component={Succssful} path="/succssful" exact />
          <PrivateRoute component={Wrong} path="/wrong" exact />
          <PrivateRoute component={History} path="/history" exact />
          <PrivateRoute component={WaitingProcesses} path="/waiting-processes" exact />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
