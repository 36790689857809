import React from "react";
import $ from "jquery";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
function SucessCashIn(props) {
  const {t} =props
  const closeDialog = (e) => { // close dialog
    <Redirect to="/" />;
    $(".modal").css({ display: "none" });
  };
  return (
    <>
      <div
        className="modal fade show"
        id="exampleModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style={{ display: `${props.showDialog}`, top: "23%" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={(e) => closeDialog(e)}>
                 <Link className="close" to="/">x</Link>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="my-4 text-center">
                <div className=" text-center  mb-5">
                  <i
                    style={{
                      fontSize: "40px",
                      height: "60px",
                      lineHeight: "60px",
                      borderRadius: "50%",
                      fontWeight: "bold",
                      width: "60px",
                    }}
                    className="icon color-green lni lni-checkmark"
                  ></i>
                </div>
                <p className="lead alert-no">
                 {t('process-being-processed')}
                </p>
              </div>
            </div>
            <div className="modal-footer text-center">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
                onClick={(e) => closeDialog()}
              >
                <Link   className="close-d" to="/">{t('done')}</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      ;
    </>
  );
}

export default SucessCashIn;
