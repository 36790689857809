import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import $ from 'jquery';
import AOS from 'aos';
import axios from 'axios';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {v4 as uuidv4} from 'uuid';
import {JSEncrypt} from 'js-encrypt';
import valid from 'card-validator';
import BottomFooter from '../../ComFooter/js/BottomFooter';
import NavHeader from '../../ComHeader/js/NavHeader';
import {getRatePriceDoller} from '../../../utils/GetRatePriceDoller';
import {errorMessageInfo} from '../../../utils/ErrorMessagesTransaction';
import {BASE_URL} from '../../../utils';
import {getPublicKey} from '../../../utils/GetPublicKey';
import '../css/main.css';
import SucessCashIn from './SucessCashIn';
import {useTranslation} from 'react-i18next';

function CashIn() {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [amount, setAmount] = useState('');
    const [uuid, setUuidV4] = useState(uuidv4());
    const [publickey, setPublicKey] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showDialog, setShowDialog] = useState('none');
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(publickey);
    useEffect(() => {
        $('#preloader').fadeOut(); // loding befor page is load
    }, []);
    useEffect(() => {
        AOS.init(); // initial aos animation libaray
        setPublicKey(getPublicKey()); // get public key
    }, [publickey, amount]);
    const initialValues = {
        // initialValues input feild
        email: '',
        senderCard: '',
        expireDate: '',
        ipinCard: '',
        amount: '',
    };
    useEffect(() => {
        if (localStorage.getItem('lang') === 'en') {
            $('html').attr('lang', 'en');
            $('body').css({direction: 'ltr'});
        } else if (localStorage.getItem('lang') === 'ar') {
            i18n.changeLanguage('ar');
            $('body').css({direction: 'rtl'});
            $('html').attr('lang', 'ar');
        }
    }, [i18n]);
    const onSubmit = (values) => {
        setPublicKey(getPublicKey()); // get public key
        let encrypted = jsEncrypt.encrypt(uuid + values.ipinCard);
        $('#preloader').fadeIn();
        const data = {
            // call own server
            expiration_date: values.expireDate.replace(/\//g, ''),
            ipin: encrypted,
            email: values.email,
            amount: values.amount,
            pan: values.senderCard.split('-').join(''),
            uuid: uuid,
            platform: 'web',
        };
        axios
            .post(BASE_URL() + 'paypal/cashin/', data, {
                headers: {
                    'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
                    token: sessionStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                // const detail = response.data;
                if (response.status === 200) {
                    // response own server Successful
                    $('#preloader').fadeOut();
                    setShowDialog('block');
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    // status failed  bad reguest
                    setErrorMessage(error.response.data.details);
                    $('#preloader').fadeOut();
                } else if (error.response.status == 406) {
                    history.push({
                        pathname: '/wrong',
                        state: {
                            error: errorMessageInfo(error.response.data.response_code, t),
                        },
                    });
                } else if (error.response.status === 401) {
                    // status failed Unauthorized partiner
                    setErrorMessage(`${t('unauthorized-partiner')}`);
                    $('#preloader').fadeOut();
                } else if (error.response.status === 422) {
                    // status failed amount less 1 %
                    setErrorMessage('amount less than 1 $');
                    $('#preloader').fadeOut();
                } else if (error.response.status === 404) {
                    // status failed Unauthorized token
                    sessionStorage.clear()
                    history.push({
                        pathname: '/singin',
                        state: {},
                    });
                } else if (error.response.status === 503) {
                    // status failed stystem maintenance
                    setErrorMessage(`${'system-maintenance'}`);
                    $('#preloader').fadeOut();
                } else if (error.response.status === 510) {
                    // status failed ebs error
                    history.push({
                        pathname: '/wrong',
                        state: {
                            error: errorMessageInfo(error.response.data.code, t),
                        },
                    });
                }
            });
    };
    const validationSchema = Yup.object({
        email: Yup.string()
            .email(`${t('invalid-email')}`)
            .required(`${t('field-required')}`),
        senderCard: Yup.string()
            .required(`${t('field-required')}`)
            .min(16, `${t('card-number-not-less-sixteen-digit')}`)
            .max(23, `${t('card-number-must-lesst-three-twinty-digit')}`),
        // .test(
        //   'test-number',
        //   'Credit Card number is invalid',
        //   (value) => valid.number(value).isValid
        // ),
        expireDate: Yup.string()
            .required(`${t('field-required')}`)
            .matches(/[\d]{2}\/[\d]{2}/, `${t('date-not-valid')}`),
        ipinCard: Yup.string()
            .required(`${t('field-required')}`)
            .min(3, `${t('ipin-not-less-three-digit')}`)
            .max(4, `${t('ipin-must-lesst-three-twinty-digit')}`)
            .matches(/^[0-9]+$/, 'no matches'),
        amount: Yup.number()
            .required(`${t('field-required')}`)
            .moreThan(4, `${t('amount-must-least-five-usd')}`)
            .lessThan(501, `${t('amount-less-five-hundred')}`),
    });
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    return (
        <>
            <SucessCashIn showDialog={showDialog} t={t}/>;
            <NavHeader active='home'/>
            <section className='section-padding pb-0'>
                <div className='container'>
                    <div className='section-header electric text-center'>
                        <h2
                            className='section-title wow fadeInDown animated'
                            data-wow-delay='0.3s'
                            style={{visibility: 'visible', animationDelay: '0.3s'}}
                        >
                            {t('paypal-cashin')}
                        </h2>
                        <div
                            className='shape wow fadeInDown animated'
                            data-wow-delay='0.3s'
                            style={{visibility: 'visible', animationDelay: '0.3s'}}
                        ></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-10 m-auto'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='row'>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='200'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor='email-sender'>
                                                {t('enter-paypal-email')}
                                            </label>
                                            <input
                                                type='email'
                                                className='form-control'
                                                name='email'
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className='help-block with-errors'>
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='100'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('enter-eender-bank-card')}</label>
                                            <input
                                                type='tel'
                                                className='form-control'
                                                name='senderCard'
                                                placeholder='xxxx-xxxx-xxxx-xxxx'
                                                {...formik.getFieldProps('senderCard')}
                                                maxLength='23'
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );
                                                    var foo = e.target.value.split('-').join('');
                                                    if (foo.length > 0) {
                                                        foo = foo
                                                            .match(new RegExp('.{1,4}', 'g'))
                                                            .join('-');
                                                    }
                                                    e.target.value = foo;
                                                }}
                                            />
                                            {formik.touched.senderCard && formik.errors.senderCard ? (
                                                <div className='help-block with-errors'>
                                                    {formik.errors.senderCard}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='300'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('expiry-date')}</label>
                                            <input
                                                type='tel'
                                                className='form-control'
                                                name='expireDate'
                                                placeholder='MM/YY'
                                                maxLength='5'
                                                {...formik.getFieldProps('expireDate')}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value
                                                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                                                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                                                        .replace(/[^\d/]/g, '');
                                                }}
                                            />
                                            {formik.touched.expireDate && formik.errors.expireDate ? (
                                                <div className='help-block with-errors'>
                                                    {formik.errors.expireDate}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='400'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('ipin')}</label>
                                            <input
                                                type='password'
                                                className='form-control'
                                                name='ipinCard'
                                                maxLength='5'
                                                {...formik.getFieldProps('ipinCard')}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );
                                                }}
                                            />
                                            {formik.touched.ipinCard && formik.errors.ipinCard ? (
                                                <div className='help-block with-errors'>
                                                    {formik.errors.ipinCard}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='500'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('enter-amount-sdg')}</label>
                                            <input
                                                type='tel'
                                                className='form-control'
                                                name='amount'
                                                maxLength='4'
                                                {...formik.getFieldProps('amount')}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );
                                                    setAmount(e.target.value);
                                                    if (e.target.value > 0) {
                                                        $('.dollar').removeClass('d-none');
                                                        setAmount(e.target.value);
                                                        if (e.target.value >= 5) {
                                                            $('.fees').removeClass('d-none');
                                                        } else {
                                                            $('.fees').addClass('d-none');
                                                        }
                                                    } else {
                                                        $('.dollar').addClass('d-none');
                                                    }
                                                }}
                                            />
                                            {formik.touched.amount && formik.errors.amount ? (
                                                <div className='help-block with-errors'>
                                                    {formik.errors.amount}
                                                </div>
                                            ) : null}
                                        </div>
                                        <p
                                            className='text-left fees d-none'
                                            style={{fontWeight: 'bolder', color: '#0d025b'}}
                                        >
                                            <p className="text-left fees">{t('total-amount-sent')} {amount} {t('usd')}</p>
                                            {t('fees-details')} {(amount * 0.05 + amount * .037 + 1).toFixed(2)} {t('usd')}
                                            <br/>
                                            <p className="text-left fees" style={{
                                                fontWeight: 'bolder',
                                                color: '#0d025b'
                                            }}>{t('exchange-rate')}{getRatePriceDoller()} {t('sdg')}</p>
                                        </p>
                                    </div>
                                    <div className='col-md-6 dollar d-none label'>
                                        <label htmlFor=''>{t('amount-usd')}</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            disabled
                                            value={`${(getRatePriceDoller() * amount + (amount * 0.05 + amount * .037 + 1) * getRatePriceDoller()).toLocaleString()}`}
                                        />
                                    </div>
                                    <div className='error col-12 text-center mt-2'>
                                        {errorMessage}
                                    </div>
                                    <div className='col-md-12 mt-3 text-center'>
                                        <button className='btn btn-common submit' type='submit'>
                                            {t('transfer')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <BottomFooter/>
            <div id='preloader'>
                <div className='loader' id='loader-1'></div>
            </div>
        </>
    );
}

export default CashIn;
