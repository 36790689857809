import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from "i18next-http-backend";

const resources = {
  en: {
    translation: {
      'tv-home': 'Home',
      history: 'History',
      'imagine-it': 'Imagine It ...Done',
      'welcome-back': 'Welcome back!',
      'login-account': 'Login to your account',
      "don't-account": "Don't have an account ? ",
      signup: 'Signup',
      'p-number': 'Enter your phone number',
      password: 'Enter your password',
      'forgot-password': 'Forgot Password ?',
      login: 'Login',
      'welcome-enayapay': 'Welcome to Enayapay!',
      'f-name': 'First name',
      'l-name': 'Last name',
      'register-account': 'Register your account',
      'already-account': 'Already have an account? ',
      'send': 'Send',
      'reset-password':'Reset Password ',
      'verify-account':'Please enter the OTP to verify your account',
      'code-has-send':'  The code has been send to  ',
      'done':'Done',
      'new-password':'Create new password',
      'reset':'Reset',
      'enter-p-number':'Please enter phone number',
      'p-number-must-ten-digit':'Phone number must less 10 digit ',
      'p-number-pass-wrong':'Phone number or password is wrong',
      'unauthorized-partiner':'Unauthorized partiner',
      'p-number-not-registered':'Phone number is not registered',
      'can’t-complete-singin':'Sorry - we can’t complete your singin at this time, please try again later.',
      'system-maintenance':'system maintenance notice',
      'p-number-already-registered':'Phone number is already registered',
      'wrong-p-number':'wrong phone number',
      'api-key-not-valid':'Your Api Key not valid',
      'bad-getway':'Bad Getway',
      'wrong-otp-number':'wrong otp number',
      'wrong':'wrong',
      'transaction-successful':'Congratulations you transaction is successful',
      'service-name':'Service Name',
      'pan':'PAN',
      'semail-sender':'E-mail sender',
      'to-card':'To card',
      'amount-sdg':'Amount in SDG',
      'amount-usd':'Amount in USD',
      'fees-usd':'Fees in USD',
      'water-fees':'Water Fees',
      'meter-fees':'Meter Fees',
      'unit-kw':'Units in KW',
      'net-amount':'Net Amount',
      'operator-name':'Operator Name',
      'phone-number':'Phone Number',
      'transaction-date':'Transaction Date',
      'meter-no':'Meter No',
      'account-no':'Account No',
      'electricity':'Electricity',
      'telecom':'Telecom',
      'paypal-cashin':'Paypal Cashin',
      'paypal-cashout':'Paypal Cashout',
      'government':'Government',
      'education':'Education',
      'coming-soon':'( COMING SOON )',
      'enable-vpn':'Please enable the VPN to access our electronic payment services.',
      'top-ups':'Top Ups',
      'bill-payment':'Bill Payment',
      'zain':'Zain',
      'sudani':'Sudani',
      'mtn':'Mtn',
      'p-number-must-start':'phone number must start with   ',
      'enter-amount-sdg':'Enter amount in SDG',
      'enter-amount-usd':'Enter amount in USD',
      'card-no':'Card No',
      'expiry-date':'Expiry Date',
      'ipin':'IPIN',
      'local-card':'Local card',
      'pay-with-paypal':'Pay With Paypal',
      'pay-with-local':'Pay with local Card',
      'payment-amount-least':'Payment amount must be at least',
      'sdg':'SDG',
      'paypal':'Paypal',
      'push-pill':'Pay bill payment',
      'confirm':'Confirm',
      'date':'Date',
      'amount':'Amount',
      'pay':'Pay',
      'cancle':'Cancle',
      'status':'Status',
      'pill-info':'Telecom Recent Bill Payment Info',
      'referral':'Referral code',
      'card':'Crad',
      'account':'Account',
      'bank-name':'Bank Name',
      'transfer':'Transfer',
      'time':'Time',
      'services-type':'Services Type ',
      'no-history':'No history transactions',
      'p-info-error':'Personal information input error',
      'p-code-not-found':'Prepaid code not found',
      'account-exhausted':'Corresponding account exhausted',
      'limit-exceeded':'Acquirer limit exceeded',
      'lost-card':'Lost card',
      'stolen-card':'Stolen card',
      'ineligible-account':'Ineligible vendor account',
      'unauthorized-usage':'Unauthorized usage',
      'expired-card':'Expired card',
      'invalid-card':'Invalid card',
      'invalid-pin':'Invalid PIN',
      'system-error-unexpected':'System error Unexpected error occurred in the system',
      'ineligible-transaction':'Ineligible transaction',
      't-not-supported':'Transaction not supported',
      'restricted-card':'Restricted Card',
      'insufficient-funds':'Insufficient funds',
      'uses-limit-exceeded':'Uses limit exceeded',
      'withdrawal-limit-exceeded':'Withdrawal limit would be exceeded',
      'pin-limit-reached':'PIN tries limit was reached',
      'limit-already-reached':'Withdrawal limit already reached',
      'credit-amount-limit':'Credit amount limit',
      'no-statement-info':'No statement information',
      's-not-available':'Statement not available',
      'invalid-amount':'Invalid amount',
      'external-decline':'External decline',
      'no-sharing':'No sharing',
      'contact-card-issuer':'Contact card issuer',
      'destination-not-available':'Destination not available',
      'routing-error':'Routing error',
      'format-error':'Format error',
      'ex-special-condition':'External decline special condition',
      'bad-cvv':'Bad CVV',
      'bad-cvv-two':'Bad CVV2',
      'invalid-transaction':'Invalid transaction',
      'pin-imit-exceeded':'PIN tries limit was exceeded',
      'bad-cavv':'Bad CAVV',
      'bad-arqc':'Bad ARQC',
      'server-up':'Server is Up',
      'none':'None',
      'biller-transaction':'Biller declined transaction',
      'unresolved-transaction-biller-timeout':'Unresolved transaction due to biller timeout',
      'biller-destination-unavailable':'Biller destination is unavailable or unreachable',
      'payment-declined-invalid':'Payment declined invalid invoice',
      'payment-Declined-wrong -data':'Payment declined wrong add data',
      'payment-declined-already-paid':'Payment declined already paid',
      'payment-declined-invalid-amount':'Payment declined invalid invoice amount',
      'invalid-ipin-block':'Invalid ipin block',
      'invalid-palication-id':'Invalid aplication id',
      'invalid-uuid':'Invalid UUID',
      'invalid-date-time':'Invalid tarn date time',
      'invalid-card-no':'Invalid card no',
      'invalid-exp-date':'Invalid exp date',
      'invalid-original-time-date':'Invalid original tran date time',
      'invalid-original-guid':'Invalid original tran guid',
      'invalid-to-card':'Invalid to card',
      'formate-error':'Formater error',
      'invalid-payee-id':'Invalid payee id',
      'duplicate-transaction':'Duplicate transaction',
      'invalid-payment-info':'Invalid payment info',
      'cannot-reversed':'Cannot be reversed',
      'invalid-account':'Invalid account',
      'invalid-mbr':'Invalid mbr',
      'invalid-account-type':'Invalid account type',
      'invalid-transaction-currency':'Invalid transaction currency',
      'invalid-authentication-type':'Invalid authentication type',
      'invalid-authentication':'Invalid authentication',
      'invalid-registration-type':'Invalid registration type',
      'invalid-user-name':'Invalid user name',
      'invalid-entity-id':'Invalid Entity Id',
      'invalid-entity-type':'Invalid entity type',
      'invalid-entity-group':'Invalid entity group',
      'invalid-phone-no':'Invalid phone no',
      'invalid-email':'Invalid email',
      'invalid-institution-id':'Invalid financial institution id',
      'invalid-user-password':'Invalid user password',
      'not-enrolled-before':'Not enrolled before',
      'cannot-generate-card':'Cannot generate card',
      'customer-already-exsists':'Customer already exsists',
      'cannot-use-card':'Cannot use card',
      'invalid-security-question':'Invalid security question',
      'invalid-security-question-answer':'Invalid security question answer',
      'invalid-job':'Invalid job',
      'invalid-date-birth':'Invalid date of birth',
      'invalid-bank-acount-number':'Invalid bank account number',
      'invalid-bank-account-type':'Invalid bank account type',
      'invalid-bank-branch-id':'Invalid bank branch id',
      'invalid-bank-id':'Invalid bank id',
      'invalid-full-name':'Invalid full name',
      'invalid-customer-number':'Invalid customer id number',
      'invalid-customer-id-type':'Invalid customer id type',
      'invalid-admin-user-name':'Invalid admin user name',
      'unauthorized-payee-id':'Unauthorized payee id',
      'unauthorized-authentication-type':'Unauthorized authentication type',
      'unauthorized-registration-type':'Unauthorized registration type',
      'unauthorized-transaction-currency':'Unauthorized transaction currency',
      'unauthorized-transaction':'Unauthorized-transaction',
      'unauthorized-card-prefix':'Unauthorized card prefix',
      'unauthorized-amount':'Unauthorized amount',
      'unauthorized-pan-category':'Unauthorized pan category',
      'unauthorized-financial-institution-id':'Unauthorized financial institution id',
      'approved-notification-customer':'Approved, but notification was not sent to customer',
      'invalid-service-info':'Invalid service info',
      'invalid-service-provider-id':'Invalid service provider id',
      'unauthorized-service-provider-id':'Unauthorized service provider id',
      'wrong-expiration-date':'Wrong new expiration date',
      'registered-card-already-updated':'Registered card already updated',
      'application-not-available-mobile':'Application not available with mobile wallet',
      'payee-not-available-mobile':'Payee not available with mobile wallet',
      'service-provider-not-available-mobile':'Service provider not available with mobile wallet',
      'wrong-mobile-number':'Wrong mobile wallet number',
      'time-transactions-exceeded':'Time between transactions exceeded',
      'invalid-last-four-digits':'Invalid last 4 digits value',
      'dynamic-fee-greater-transaction-amount':'Dynamic fee is greater than transaction amount',
      'original-transaction-not-found':'Original transaction not found',
      'original-transaction-cnnot-refunded':'Original transaction cannot be refunded',
      'original-transaction-refunded':'Original transaction has been refunded before',
      'channel-id-not-found':'Channel id not found',
      'transaction-initiated-success':'Transaction initiated successfully',
      'waiting-customer-authenticating-completion':'Waiting customer for authenticating completion',
      'no-available-card-application':'No available card for this application',
      'payment-total-transaction-amount':'Payment details total amount mismatch transaction amount',
      'invalid-payment-details-limit-exceeded':'Invalid payment details limit exceeded of allowed number of records',
      'invalid-account-details':'Invalid account number field in payment details',
      'invalid-amount-payment-details':'Invalid amount field in payment details',
      'invalid-orig-uuid':' Invalid orig tran uuid',
      'invalid-otp-block':'Invalid otp block',
      'system-error':'System error',
      'hermes-not-nitialized':'hermes not initialized',
      'fee-amount-less-min-fee':'Fee amount is less than min fee amount',
      'fee-amount-greater-tahn-max-fee"':'Fee amount is greater than max fee amount"',
      'no-configuration-external-application':'No configuration found in external application',
      'invalid-dynamic-fee-amount':'Invalid dynamic fee amount',
      'dynamic-fee-greater-than-amount':'Dynamic fee is greater than transaction amount',
      'invalid-id-no':'Invalid id no',
      'invalid-id-type':'Invalid id type',
      'invalid-merchant-name':'Invalid merchant name',
      'invalid-merchant-city':'Invalid merchant city',
      'invalid-merchant-mobile-no':'Invalid merchant mobile no',
      'invalid-merchant-account-type':'Invalid merchant account type',
      'invalid-merchant-account-reference':'Invalid merchant account reference',
      'invalid-merchant-id':'Invalid merchant id',
      'system-failed-check-duplicates':'System failed to check duplicates',
      'no-otp-foun-validated':'No OTP found to be validated',
      'otp-operation-failed':'OTP operation failed',
      'could-not-send-otp':'Could not send the OTP',
      'otp-expired':'OTP expired',
      'otp-limit-exceeded':'OTP tries limit exceeded',
      'invalid-otp':'Invalid OTP',
      'timeout-response-code':'Time OUT respone code',
      'cant\'t-complete-transaction':'Sorry - we can’t complete your transaction at this time, please try again later',
      'field-required':'This field is required',
      'card-number-not-less-sixteen-digit':'card number You should not be less than 16 digit',
      'card-number-not-less-three-digit':'card number You should not be less than 3 digit',
      'card-number-must-lesst-three-twinty-digit':'The card number must not less than 23 digit',
      'amount-must-least-five-usd':'Payment amount must be at least 5 USD',
      'amount-less-five-hundred':'Payment amount must be less than  $500.00',
      'date-not-valid':'date is not valid',
      'enter-paypal-email':' Enter Paypal account email',
      'enter-eender-bank-card':'Enter Sender Bank Card Number',
      'total-amount-sent':'Total amount sent',
      'fees-details':'Fee: 3.7% Paypal Fees , Platform 5% + $1 / Fee Total:',
      'exchange-rate':' Exchange Rate: $1.00 USD =',
      'name':'Name',
      'token':'Token',
      'payment-limit':'Payment limit is',
      'copyright':'Copyright',
      'reserved':'reserved',
      'process-being-processed':'Sorry, the process is being processed and our team will contact you and notify you when the process is complete',
      'total-amount':' Total Amount',
      'fees':'Fess',
      'total-amount-received-sdg':'Total amount received in SDG',
      'enter-account-receive-no':'Enter Account receive number',
      'enter-card-receive-no':'Enter card receive number',
      'receive-phone-number':'Receive phone number',
      'usd':'USD',
    },
  },
  ar: {
    translation: {
      'tv-home': 'الرئسية',
      history: 'سجل المعاملات',
      'imagine-it': 'تخيل ذلك ... تم',
      'welcome-back': 'تسجيل الدخول إلى حسابك',
      'login-account': 'مرحبا بعودتك!',
      "don't-account": '  ليس لديك حساب؟  ',
      signup: '  انشاء حساب  ',
      'p-number': 'أدخل رقم هاتفك',
      password: 'ادخل كلمة المرور ',
      'forgot-password': 'هل نسيت كلمة المرور ؟',
      login: 'تسجل دخول',
      'welcome-enayapay': 'مرحبا بكم في Enayapay!',
      'f-name': 'الاسم الاول',
      'l-name': 'الاسم العائلة',
      'register-account': 'انشي حسابك معنا ',
      'already-account': ' هل لديك حساب؟  ',
      'send': 'ارسال',
      'reset-password':'إعادة تعيين كلمة المرور ',
      'verify-account':'الرجاء إدخال كلمة المرور لمرة واحدة للتحقق من حسابك',
      'code-has-send':'  تم إرسال الرمز إلى  ',
      'done':'موافق',
      'new-password':'إنشاء كلمة مرور جديدة ',
      'reset':'إعادة تعيين',
      'enter-p-number':'الرجاء إدخال رقم الهاتف',
      'p-number-must-ten-digit':'يجب أن يكون رقم الهاتف أقل من 10 أرقام ',
      'p-number-pass-wrong':'رقم الهاتف أو كلمة المرور خاطئة',
      'unauthorized-partiner':'شريك غير مصرح به',
      'p-number-not-registered':'رقم الهاتف غير مسجل',
      'can’t-complete-singin':'عذرًا - لا يمكننا إكمال تسجيلك في الوقت الحالي ، يرجى المحاولة مرة أخرى لاحقًا.',
      'system-maintenance':'إشعار صيانة النظام',
      'p-number-already-registered':'رقم الهاتف مسجل بالفعل',
      'wrong-p-number':'رقم هاتف خاطئ',
      'api-key-not-valid':'مفتاح Api الخاص بك غير صالح',
      'bad-getway':'مدخل خطأ',
      'wrong-otp-number':'رقم التحقق خاطي',
      'wrong':'خطأ',
      'transaction-successful':'تهانينا تم نحاح المعاملة',
      'service-name':'اسم الخدمة',
      'pan':'رقم البطاقة',
      'email-sender':'ايميل المرسل',
      'to-card':'رقم البطاقة المرسل لها',
      'amount-sdg':'المبلغ بالجنية السوداني',
      'amount-usd':'المبلغ بالدولار',
      'fees-usd':'رسوم المعاملة بالدولار',
      'water-fees':'رسوم الماء',
      'meter-fees':' رسوم العداد',
      'unit-kw':'الوحدات بالكيلوواط',
      'net-amount':'المبلغ بعد الخصم',
      'operator-name':'اسم المشغل',
      'phone-number':'رقم الهاتف',
      'transaction-date':'وقت المعاملة',
      'meter-no':'رقم العداد',
      'account-no':'رقم الحساب',
      'electricity':'الكهرباء',
      'telecom':'خدمات الاتصالات ',
      'paypal-cashin':'بايبال كاش ان',
      'paypal-cashout':'بايبال كاش اوت',
      'government':'الخدمات الحكومية',
      'education':'خدمات التعليم',
      'coming-soon':'( قريبا  )',
      'enable-vpn':'يرجى تمكين VPN للوصول إلى خدمات الدفع الإلكترونية الخاصة بنا.',
      'top-ups':'خدمات الدفع المقدم',
      'bill-payment':'خدمات الدفع الاجل',
      'zain':'زين',
      'sudani':'سوداني',
      'mtn':'ام تي ان',
      'p-number-must-start':'  يجب ان يبدء رقم الهاتف ب  ',
      'enter-amount-sdg':'ادخل المبلغ بالجنية السوداني',
      'enter-amount-usd':'ادخل المبلغ بالدولار الامريكي',
      'card-no':'رقم البطاقة',
      'expiry-date':'تاريخ انتهاء البطاقة',
      'ipin':'رقم الاننرنت السري',
      'local-card':'بطاقة محلية',
      'pay-with-paypal':'دفع باستخدام بايبال',
      'pay-with-local':'دفع باستخدام بطاقة محلية',
      'payment-amount-least':'يجب أن يكون مبلغ المعاملة على الأقل',
      'sdg':'جنية سوداني',
      'paypal':'بايبال',
      'push-pill':'دفع الفاتورة',
      'confirm':'تحقق',
      'date':'التاريخ',
      'amount':'المبلغ',
      'pay':'دفع',
      'cancle':'الغاء',
      'status':'حالة الاستعلام',
      'pill-info':'معلومات الفاتورة',
      'referral':'كود الاحالة',
      'card':'بطاقة',
      'account':'حساب بنكي',
      'bank-name':'اسم البنك',
      'transfer':'تحويل',
      'time':'الزمن',
      'services-type':'نوع الخدمة',
      'no-history':'لم تقم باي معاملات',
     
      'p-info-error':'ادخال بيانات دفع خاطئ',
      'p-code-not-found':'الرمز غير موجود',
      'account-exhausted':'اعد تسجيل الدخول',
      'limit-exceeded':'تجاوزت الحد المسموح',
      'lost-card':'هذة البطاقة مفقودة وتم التبليغ عنها ',
      'stolen-card':' هذة البطاقة مسروقة  ',
      'ineligible-account':' رقم الحساب خاطئ' ,
      'unauthorized-usage':'البطاقة غير مفعلة',
      'expired-card':'بطاقة منتهية',
      'invalid-card':'رقم البطاقة خاطي',
      'invalid-pin':'رقم الانترنت السري خاطئ',
      'system-error-unexpected':'خطأ في النظام',
      'ineligible-transaction':'عملية دفع غير مسموحة',
      't-not-supported':'خطأ في ادخال البيانات او مشكلة في الحساب',
      'restricted-card':'عملية دفع غير معرفة',
      'insufficient-funds':' بطاقة محدودة',
      'uses-limit-exceeded':'المبلغ غير كافي',
      'withdrawal-limit-exceeded':'تم تجاوز الحد الاقصى للمعامللات',
      'pin-limit-reached':' تم تجاوز الحد الاقصى من محاولات ادخال الرقم السري',
      'limit-already-reached':'تم تجاوز الحد الاقصى من التحويلات',
      'credit-amount-limit':'تم الوصول للمبلغ المسموح بة للتحويلات',
      'no-statement-info':'لا توجد معلومات بيان',
      's-not-available':'البيان غير متوفر',
      'invalid-amount':'تنسيق المبلغ خاطئ  ',
      'external-decline':'ادخال خاطئ، فشلت المعاملة',
      'no-sharing':'لايمكن المشاركة',
      'contact-card-issuer':'خطأ في من جهة اصدار البطاقة',
      'destination-not-available':'مزود الخدمة لايمكن الوصول الية حاليا ',
      'routing-error':'ادخال خاطئ لرقم البطاقة',
      'format-error':'ادخال خاطئ',
      'ex-special-condition':'فشل في النظام',
      'bad-cvv':'خاطئ CVV',
      'bad-cvv-two':'خاطئ CVV2',
      'invalid-transaction':'معاملة غير مسموح بها',
      'pin-imit-exceeded':'تم تجاوز الحد الاقصى من محاولات ادخال الرقم السري',
      'bad-cavv':'خاطئ CAVV',
      'bad-arqc':'خاطئ ARQC',
      'server-up':'الخادم موجود',
      'none':'فشلت العملية حاول مرة اخرى',
      'biller-transaction':'تم رفض عملية الدفع',
      'unresolved-transaction-biller-timeout':'معاملة لم يتم حلها بسبب انتهاء مهلة المفوتر',
      'biller-destination-unavailable':'لا يمكن اكمال عملية الدفع الان',
      'payment-declined-invalid':'خطأ في رقم الفاتورة',
      'payment-Declined-wrong -data':'اعد ادخال بيانات الفاتورة',
      'payment-declined-already-paid':'الفاتورة مدفوعة مسبقا',
      'payment-declined-invalid-amount':'خطأ في المبلغ',
      'invalid-ipin-block':'خطأ في ادخال رمز الانترنت السري',
      'invalid-palication-id':'خطأ في الطلب',
      'invalid-uuid':'رمز UUID خاطئ',
      'invalid-date-time':'وقت تاريخ غير صالح',
      'invalid-card-no':'رقم البطاقة غير صحيح',
      'invalid-exp-date':'تاريخ انتهاء الصلاحية غير صالح',
      'Invalid-original-time-date':'وقت تاريخ التحويل الأصلي غير صالح',
      'invalid-original-guid':'الدليل الأصلي غير صالح',
      'invalid-to-card':'البطاقة المحول لها غير صالحة',
      'formate-error':'خطأ في التنسيق',
      'invalid-payee-id':'معرف المدفوع لأمره غير صالح',
      'duplicate-transaction':'معاملة مكررة يرجى التحقق من معاملتك الأخيرة',
      'invalid-payment-info':'معلومات الدفع غير صحيحة',
      'cannot-reversed':'لا يمكن عكس',
      'invalid-account':'حساب غير صالح',
      'invalid-mbr':'خاطئ mbr',
      'invalid-account-type':'نوع الحساب غير صالح',
      'invalid-transaction-currency':'عملة الصفقة غير صالحة',
      'invalid-authentication-type':'نوع المصادقة غير صالح',
      'invalid-authentication':'مصادقة غير صالحة',
      'invalid-registration-type':'نوع التسجيل غير صالح',
      'invalid-user-name':'اسم المستخدم غير صالح',
      'invalid-entity-id':'معرف الكيان غير صالح',
      'invalid-entity-type':'Invalid entity type',
      'invalid-entity-group':'مجموعة الكيانات غير صالحة',
      'invalid-phone-no':'رقم الهاتف غير صحيح',
      'invalid-email':'عنوان البريد الإلكتروني غير صالح',
      'invalid-institution-id':'معرف مؤسسة مالية غير صالح',
      'invalid-user-password':'كلمة مرور المستخدم غير صحيحة',
      'not-enrolled-before':'غير مسجل من قبل',
      'cannot-generate-card':'لا يمكن إنشاء البطاقة',
      'customer-already-exsists':'الزبون موجود بالفعل',
      'cannot-use-card':'لا يمكن استخدام هذة البطاقة',
      'invalid-security-question':'سؤال الأمان غير صالح',
      'invalid-security-question-answer':'إجابة سؤال الأمان غير صالحة',
      'invalid-job':'وظيفة غير صحيحة',
      'invalid-date-birth':'تاريخ ميلاد غير صالح',
      'invalid-bank-acount-number':'رقم الحساب المصرفي غير صحيح',
      'invalid-bank-account-type':'نوع الحساب المصرفي غير صالح',
      'invalid-bank-branch-id':'معرف فرع البنك غير صالح',
      'invalid-bank-id':'معرف البنك غير صالح',
      'invalid-full-name':'الاسم الكامل غير صالح',
      'invalid-customer-number':'رقم هوية العميل غير صالح',
      'invalid-customer-id-type':'نوع معرف العميل غير صالح',
      'invalid-admin-user-name':'اسم مستخدم المسؤول غير صالح',
      'unauthorized-payee-id':'معرف المدفوع لأمره غير مصرح به',
      'unauthorized-authentication-type':'نوع المصادقة غير مصرح به',
      'unauthorized-registration-type':'نوع التسجيل غير مصرح به',
      'unauthorized-transaction-currency':'عملة المعاملة غير مصرح بها',
      'unauthorized-transaction':'معاملة غير مصرح بها',
      'unauthorized-card-prefix':'بادئة بطاقة غير مصرح بها',
      'unauthorized-amount':'مبلغ غير مصرح به',
      'unauthorized-pan-category':'فئة غير مصرح بها',
      'unauthorized-financial-institution-id':'معرف مؤسسة مالية غير مصرح بها',
      'approved-notification-customer':'تمت الموافقة ، ولكن لم يتم إرسال الإخطار إلى العميل',
      'invalid-service-info':'معلومات الخدمة غير صالحة',
      'invalid-service-provider-id':'معرف مقدم الخدمة غير صالح',
      'unauthorized-service-provider-id':'معرف مزود خدمة غير مصرح به',
      'wrong-expiration-date':'تاريخ انتهاء الصلاحية الجديد خاطئ',
      'registered-card-already-updated':'تم تحديث بيانات البطاقة المسجلة بالفعل',
      'application-not-available-mobile':'التطبيق غير متوفر مع محفظة الهاتف ',
      'payee-not-available-mobile':'المدفوع لأمره غير متوفر مع محفظة الهاتف ',
      'service-provider-not-available-mobile':'مزود الخدمة غير متوفر مع محفظة الهاتف ',
      'wrong-mobile-number':'رقم محفظة الهاتف خاطئ',
      'time-transactions-exceeded':'تم تجاوز الوقت  المسموخ به بين المعاملات',
      'invalid-last-four-digits':'قيمة 4 أرقام الاخيرة غير صالحة',
      'dynamic-fee-greater-transaction-amount':'الرسوم أكبر من مبلغ المعاملة',
      'original-transaction-not-found':'لم يتم العثور على المعاملة الأصلية',
      'original-transaction-cnnot-refunded':'لا يمكن استرداد المعاملة الأصلية',
      'original-transaction-refunded':'تم استرداد المعاملة الأصلية من قبل',
      'channel-id-not-found':'معرّف القناة غير موجود',
      'transaction-initiated-success':'بداية المعاملة بنجاح',
      'waiting-customer-authenticating-completion':'في انتظار العميل للمصادقة على الاكتمال',
      'no-available-card-application':'لا توجد بطاقة متاحة لهذا التطبيق',
      'payment-total-transaction-amount':'تفاصيل الدفع غير متتطابقة مع مبلغ المعاملة الكلي',
      'invalid-payment-details-limit-exceeded':'تم تجاوز حد تفاصيل الدفع غير الصالحة لعدد السجلات المسموح به',
      'invalid-account-details':'حقل رقم الحساب غير صالح في تفاصيل الدفع',
      'invalid-amount-payment-details':'حقل المبلغ غير صالح في تفاصيل الدفع',
      'invalid-orig-uuid':'رقم التحقق غير صالح',
      'invalid-otp-block':'رقم التحقق غير صالخ',
      'system-error':'خطأ في النظام',
      'hermes-not-nitialized':'لم تتم تهيئة hermes',
      'fee-amount-less-min-fee':'مبلغ الرسوم اقل من الحد اقل لمبلغ الرسوم',
      'fee-amount-greater-tahn-max-fee"':'مبلغ الرسوم أكبر من الحد الأقصى المسموح به لمبلغ الرسوم"',
      'no-configuration-external-application':'لم يتم العثور على التكوين خارج التطبيق  ',
      'invalid-dynamic-fee-amount':'مبلغ الرسوم غير صالح',
      'dynamic-fee-greater-than-amount':'الرسوم اكبر من مبلغ المعاملة',
      'invalid-id-no':'رقم معرف غير صالح',
      'invalid-id-type':'نوع معرف غير صالح',
      'invalid-merchant-name':'اسم التاجر غير صالح',
      'invalid-merchant-city':'مدينة التاجر غير صالحة',
      'invalid-merchant-mobile-no':'رقم هاتف التاجر غير صالح',
      'invalid-merchant-account-type':'نوع حساب التاجر غير صالح',
      'invalid-merchant-account-reference':'مرجع حساب التاجر غير صالح',
      'invalid-merchant-id':'معرف التاجر غير صالح',
      'system-failed-check-duplicates':'فشل النظام في التحقق من العمليات المتكررة',
      'no-otp-foun-validated':'لم يتم العثور علي رمز التحقق',
      'otp-operation-failed':'فشلت عملية ارسال رمز التحقق',
      'could-not-send-otp':'تعذر ارسال رمز التحقق ',
      'otp-expired':'تم انتهاء مدة رمز التحقق',
      'otp-limit-exceeded':'تم تجاوز الحد المسموح به من المحاولات لادخال رمز التحقق',
      'invalid-otp':'رمز التحقق غير صحيح',
      'timeout-response-code':'Time OUT respone code',
      'cant\'t-complete-transaction':'عذرًا - لا يمكننا إكمال معاملتك في الوقت الحالي ، يرجى المحاولة مرة أخرى لاحقًا',
      'field-required':'هذا الحقل مطلوب',
      'card-number-not-less-sixteen-digit':'رقم البطاقة يجب ألا يقل عن 16 رقم',
      'ipin-not-less-three-digit':'رقم الانترنت السري يجب ألا يقل عن 3 رقم',
      'card-number-must-lesst-three-twinty-digit':'يجب ألا يقل رقم البطاقة عن 23 رقمًا',
      'ipin-must-lesst-three-twinty-digit':'يجب ألا يقل رقم الانترنت السري عن 4 رقمًا',
      'amount-must-least-five-usd':'يجب ألا يقل مبلغ الدفع عن 5 دولارات أمريكية',
      'amount-less-five-hundred':'يجب أن يكون دفع المبلغ أقل من 500.00 دولار امريكي',
      'date-not-valid':'التاريخ غير صحيح',
      'enter-paypal-email':'أدخل البريد الإلكتروني لحساب بايبال',
      'enter-eender-bank-card':'أدخل رقم بطاقة البنك المرسل',
      'total-amount-sent':'المبلغ الإجمالي المرسل',
      'fees-details':'الرسوم: 3.7٪ رسوم بايبال ، المنصة 5٪ + 1 دولار / إجمالي الرسوم:',
      'exchange-rate':'سعر الصرف: 1.00 دولار أمريكي =',
      'name':'الاسم',
      'token':'رمز التعبئة',
      'payment-limit':'اعلي مبلغ مسموح به في كل معاملة هو',
      'copyright':'حقوق النشر',
      'reserved':'  محفوظة',
      'process-being-processed':'عذرًا ، تتم معالجة العملية وسيقوم فريقنا بذلك الاتصال بك وإعلامك عند اكتمال العملية',
      'total-amount':'المبلغ الكلي',
      'fees':'الرسوم',
      'total-amount-received-sdg':'المبلغ الإجمالي المستلم بالجنيه السوداني',
      'enter-account-receive-no':' ادخل رقم الحساب المرسل اليها',
      'enter-card-receive-no':'ادخل رقم البطاقة المرسل اليها',
      'receive-phone-number':'ادخل رقم هاتف المرسل اليه',
      'usd':'دولار امريكي',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: '',
  fallbackLng: 'en',
});

export default i18n;
