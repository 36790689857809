import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isLogin } from '../../../utils';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { IoIosLogOut } from 'react-icons/io';
import '../css/main.css';

function NavHeader({ active }) {
  const { t, i18n } = useTranslation();
  const [tvLange, setTvLange] = useState('عربي');
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      setTvLange('عربي');
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      setTvLange('English');
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
      require('../../../index-rtl.css');
    }
  }, [i18n]);
  // click user btn logout to clear session storge
  function clear() {
    sessionStorage.clear();
  }
  const changeLanguage = () => {
    if (i18n.language === 'en') {
      window.location.reload(false);
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
      i18n.changeLanguage('ar');
      localStorage.setItem('lang', 'ar');
      setTvLange('English');
    } else if (i18n.language === 'ar') {
      window.location.reload(false);
      $('body').css({ direction: 'ltr' });
      $('html').attr('lang', 'en');
      $('head').append(
        '<link rel="stylesheet" href="index-rtl.css" type="text/css" />'
      );
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
      setTvLange('عربي');
    }
  };
  return (
    <header id='header-wrap'>
      <nav className='navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar'>
        <div className='container'>
          <Link to='/home' className='navbar-brand text-warning'>
            <img
              className='logo'
              src='./images/final-logo.svg'
              alt='images logo website'
            />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <i className=' lni lni-menu'></i>
          </button>
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <ul className='navbar-nav mr-auto w-100 justify-content-end clearfix'>
              <li className={`${active === 'home' ? 'active' : ''} nav-item`}>
                <Link className='nav-link' to='/home'>
                {t('tv-home')}
                </Link>
              </li>
              {isLogin() ? (
                <li
                  className={`${active === 'history' ? 'active' : ''} nav-item`}
                >
                  <Link className='nav-link' to='/history'>
                    {t('history')}
                  </Link>
                </li>
              ) : (
                ''
              )}
              {isLogin() ? (
                <li
                  className={`${active === 'logout' ? 'active' : ''} nav-item`}
                  onClick={() => {
                    clear();
                  }}
                >
                  <Link
                    className='nav-link'
                    to='/'
                    onClick={() => {
                      clear();
                    }}
                  >
                    <IoIosLogOut style={{ fontSize: '1.3rem' }} />
                  </Link>
                </li>
              ) : (
                ''
              )}
              <li className={`nav-item`}>
                <h3 className='lang' onClick={() => changeLanguage()}>
                  {tvLange}
                </h3>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default NavHeader;
