import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './locale/i18n';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './responsive.css';
import 'jquery/dist/jquery.js';
import 'popper.js/dist/umd/popper.js';
import 'bootstrap/dist/js/bootstrap.js';
import App from './App';
import { MemoryRouter } from 'react-router';
sessionStorage.setItem('lang', 'en');

ReactDOM.render(
  <Suspense fallback='loading'>
  <App />
  </Suspense>, document.getElementById('root')
);

