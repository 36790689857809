import React, { useEffect } from 'react';
import $ from "jquery";
import BottomFooter from './ComFooter/js/BottomFooter';
import { Redirect, useHistory, useLocation } from 'react-router';
import NavHeader from './ComHeader/js/NavHeader';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Succssful() {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    $('#preloader').fadeOut();
  }, []);
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  if (location.state) {
    const stu = location.state.serviceName === "Electrcity" ? true : false
    const cashoutStu = location.state.serviceName === "PaypalCashOut" || location.state.serviceName === "PaypalCashIn" ? true : false
    const cashIn = location.state.serviceName === "PaypalCashIn" ? true : false
    const locale =location.state.locale==='locale'?true:false
    // const amoutType = location.state.serviceName === "PaypalCashOut" || location.state.serviceName === "Electrcity" || location.state.serviceName === "Bill Payment" || location.state.serviceName === "Top Ups" ? 'SDG' : 'USD'
    const handelDoneSuccssful = () => {
      localStorage.removeItem('payeeId')
      localStorage.removeItem('order')
      localStorage.removeItem('checkRadio')
      localStorage.removeItem('servicesName')
      localStorage.removeItem('companyName')
      history.push({
        pathname: '/'
      });
    }
    return (
      <>
        <NavHeader active="home" />
        <section id="contact" className="section-padding ">
          <div className="container">
            <div className="row justify-content-center contact-form-area wow fadeInUp" data-wow-delay="0.3s">
              <div className="col-md-4 services-item confirm">
                <div className="description text-center p-3">
                  <h5 className="">{t('transaction-successful')}</h5>
                </div>
                <div className="icon text-center color-green mb-5">
                  <i className="lni lni-checkmark" style={{color:'white'}}></i>
                </div>
                <div className="row justify-content-center">
                  <div className="col-6 text-left">
                    <h6 className="">{t('service-name')}</h6>
                  </div>
                  <div className="col-6 text-left">
                    <h6 className=""> {location.state.serviceName}</h6>
                  </div>
                  <hr />
                  <>
                  {locale?
                  <>
                    <div className="col-6 text-left">
                    <h6 className="">{t('pan')}</h6>
                  </div>
                  <div className="col-6 text-left">
                    <h6 className=""> {location.state.detail.pan}</h6>
                  </div>
                  <hr />
                  </>:<>         
                  <div className="col-6 text-left">
                    <h6 className="">{t('email-sender')}</h6>
                  </div>
                  <div className="col-6 text-left">
                    <h6 className="" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}> {location.state.detail.emailSender}</h6>
                  </div>
                  <hr />
                  </>}
                  </>
                  <>
                    {/*{cashoutStu ? (*/}
                    {/*  // <>*/}
                    {/*  //   <div className="col-6 text-left">*/}
                    {/*  //     <h6 className="">{t('to-card')}</h6>*/}
                    {/*  //   </div>*/}
                    {/*  //   <div className="col-6 text-left">*/}
                    {/*  //     <h6 className=""> {location.state.detail.toCard}</h6>*/}
                    {/*  //   </div>*/}
                    {/*  // </>*/}
                    {/*) : ('')}*/}
                  </>
                  <hr />
                  <div className="col-6 text-left">
                    <h6 className="">{t('amount-sdg')} </h6>
                  </div>
                  <div className="col-6 text-left">
                    <h6 className=""> {location.state.detail.amount}</h6>
                    <h6 className=""> {location.state.detail.amount_sdg}</h6>
                  </div>
                  <hr />
                 {locale?'':<>
                 <div className="col-6 text-left">
                    <h6 className="">{t('amount-usd')}</h6>
                  </div>
                  <div className="col-6 text-left">
                    <h6 className=""> {location.state.detail.amount_usd}</h6>
                  </div>
                  <hr />
                 </>}
                  <>
                    {cashIn && (
                      <>
                        <div className="col-6 text-left">
                          <h6 className="">{t('fees-usd')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.enaya_fee.toFixed(2)}</h6>
                        </div>
                        <hr />
                      </>
                    )}
                  </>
                  <>
                    {stu ? (
                      <>
                        <div className="col-6 text-left">
                          <h6 className="">{t('token')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.token}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('account-no')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.account_no}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('meter-no')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.meter_number}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('name')}</h6>
                        </div>
                        <div className="col-6 text-capitalize text-left">
                          <h6 className="">{location.state.detail.customer_name}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('water-fess')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.water_fees}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('meter-fees')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.meter_fees}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('unit-kw')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.units_in_kWh + " KWH"}</h6>
                        </div>
                        <hr />
                        <div className="col-6 text-left">
                          <h6 className="">{t('net-amount')}</h6>
                        </div>
                        <div className="col-6 text-left">
                          <h6 className="">{location.state.detail.net_amount} SDG</h6>
                        </div>
                        <hr />
                      </>
                    ) : (
                      <>
                        {cashoutStu ? ('') : (
                          <>
                            <div className="col-6 text-left">
                              <h6 className="">{t('phone-number')}</h6>
                            </div>
                            <div className="col-6 text-left">
                              <h6 className=""> {location.state.detail.phone_number}</h6>
                            </div>
                            <hr />
                            <div className="col-6 text-left">
                              <h6 className="">{t('operator-name')}</h6>
                            </div>
                            <div className="col-6 text-left">
                              <h6 className="">{location.state.company}</h6>
                            </div>
                            <hr />
                          </>
                        )}
                      </>
                    )}
                  </>
                  <div className="col-6 text-left">
                    <h6 className="">{t('transaction-date')}</h6>
                  </div>
                  <div className="col-6 text-left">
                    <h6 className="">{moment().format('LLL')}</h6>
                  </div>
                  <hr />
                </div>
                <div className="text-center mt-4">
                  <button className="btn btn-common submit " id="" type="" onClick={() => handelDoneSuccssful()}>{t('done')}</button>
                  {/* <button className="btn btn-border submit" id="" type=""> share<i className="material-icons text-warning"></i></button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <BottomFooter />
        <Link to="#" className="back-to-top">
          <i className="material-icons">
            north
        </i>
        </Link>
        <div id="preloader">
          <div className="loader" id="loader-1"></div>
        </div>
      </>
    )
  } else {
    sessionStorage.clear()
    return (
      <Redirect to="/" />
    )
  }
}

export default Succssful
