import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import AOS from 'aos';
import IntlTelInput from 'react-intl-tel-input';
import BottomFooter from '../../ComFooter/js/BottomFooter';
import NavHeader from '../../ComHeader/js/NavHeader';
import {errorMessageInfo} from '../../../utils/ErrorMessagesTransaction';
import {getRatePriceDoller} from '../../../utils/GetRatePriceDoller';
import {useHistory} from 'react-router';
import axios from 'axios';
import {BASE_URL} from '../../../utils';
import Error from '../../ErrorComponent/Error';
import {useTranslation} from 'react-i18next';

function CashOut(props) {
    const {t, i18n} = useTranslation();
    const [cardReceive, setCardReceive] = useState('');
    const [amount, setAmount] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [codeRevierd, setCodeRevierd] = useState('EnayaPay');
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const [priceDoller, setPriceDoller] = useState();
    const [bankName, setBankName] = useState();
    const [bankAccount, setBankAccount] = useState();
    const [cardTypeError, setCardTypeError] = useState();
    const [fees, setFees] = useState(0);
    const [checkRadio, setCheckRadio] = useState('card');
    let [cityName, setCityName] = useState();
    const [hasError, setHasError] = useState(false);
    const history = useHistory();
    useEffect(() => {
        axios
            .get('https://ipapi.co/json/') // get ip address
            .then((res) => {
                setCityName(res.data.city);
                if (cityName === 'Khartoum') {
                    setHasError(true);
                }
            })
            .catch((error) => {
            });
    }, [cityName]);
    useEffect(() => {
        setErrorMessage('');
        $('#preloader').fadeOut(); // loading befor page is load
        AOS.init(); // initial aos animation libaray
        setPriceDoller(getRatePriceDoller()); // get rate price dollar
    }, [
        amount,
        firstName,
        lastName,
        phoneNumber,
        cardReceive,
        priceDoller,
        errorMessage,
    ]);
    useEffect(() => {
        if (localStorage.getItem('lang') === 'en') {
            $('html').attr('lang', 'en');
            $('body').css({direction: 'ltr'});
        } else if (localStorage.getItem('lang') === 'ar') {
            i18n.changeLanguage('ar');
            $('body').css({direction: 'rtl'});
            $('html').attr('lang', 'ar');
        }
    }, [i18n]);
    const payWithPaypal = (e) => {
        e.preventDefault();
        $('#preloader').fadeIn();
        let cardNo = checkRadio === 'account' ? bankAccount : cardReceive;
        const data = {
            receiver_first_name: firstName,
            receiver_last_name: lastName,
            amount: (parseFloat(amount).toFixed(2) - parseFloat(fees)).toFixed(2),
            currency: 'USD',
            card_no: cardNo,
            transfer_type: checkRadio,
            platform: 'web',
            receiver_phone_number: phoneNumber.slice(1).replace(/ +/g, ''),
        };
        axios
            .post(`${BASE_URL()}paypal/cashout/`, data, {
                // send data to own server cashout
                headers: {
                    'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
                    token: sessionStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                localStorage.setItem('servicesName', 'PaypalCashOut');
                localStorage.setItem('checkRadio', checkRadio);
                window.open(response.data.approval_url, '_self');
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    // status failed  bad reguest
                    setErrorMessage(error.response.data.details);
                    alert(error.response.data.details)
                    console.log(error.response.data.details)
                    $('#preloader').fadeOut();
                } else if (error.response.status === 401) {
                    // status failed Unauthorized partiner
                    setErrorMessage(`${t('unauthorized-partiner')}`);
                    $('#preloader').fadeOut();
                } else if (error.response.status === 404) {
                    // status failed Unauthorized token
                    sessionStorage.clear();
                    history.push({
                        pathname: '/singin',
                        state: {},
                    });
                } else if (error.response.status === 555) {
                    // status failed order id not on Approve
                    setErrorMessage(error.response.data.detials);
                    $('#preloader').fadeOut();
                } else if (error.response.status === 503) {
                    // status failed stystem maintenance
                    setErrorMessage(`${'system-maintenance'}`);
                    $('#preloader').fadeOut();
                } else if (error.response.status === 510) {
                    // status failed ebs error
                    history.push({
                        pathname: '/wrong',
                        state: {
                            error: errorMessageInfo(error.response.data.code, t),
                        },
                    });
                }
            });
    };
    return (
        <>
            <NavHeader active='home'/>
            <section id='' className='section-padding pb-0'>
                <div className='container'>
                    <div className='section-header electric text-center'>
                        <h2
                            className='section-title wow fadeInDown animated'
                            data-wow-delay='0.3s'
                            style={{visibility: 'visible', animationDelay: '0.3s'}}
                        >
                            {t('paypal-cashout')}
                        </h2>
                        <div
                            className='shape wow fadeInDown animated'
                            data-wow-delay='0.3s'
                            style={{visibility: 'visible', animationDelay: '0.3s'}}
                        ></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-10 m-auto'>
                            <form onSubmit={(e) => payWithPaypal(e)}
                                  method='post'>
                                <div className='row'>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='200'
                                    >
                                        <label htmlFor='first-name'>{t('f-name')}</label>
                                        <input
                                            type='text'
                                            name='first-name'
                                            id='first-name'
                                            className='form-control'
                                            required
                                            autoComplete='off'
                                            pattern='[A-Za-z]{3,30}'
                                            onInput={(e) => setFirstName(e.target.value)}
                                        />
                                        <div
                                            style={{marginTop: '6px'}}
                                            className='help-block with-errors phone error text-center'
                                        >
                                            {errorMessage}
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='200'
                                    >
                                        <label htmlFor='last-name'>{t('l-name')}</label>
                                        <input
                                            type='text'
                                            name='last-name'
                                            id='last-name'
                                            className='form-control'
                                            required
                                            autoComplete='off'
                                            pattern='[A-Za-z]{3,30}'
                                            onInput={(e) => setLastName(e.target.value)}
                                        />
                                        <div
                                            style={{marginTop: '6px'}}
                                            className='help-block with-errors phone error text-center'
                                        >
                                            {errorMessage}
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='300'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor='phone-number'>{t('receive-phone-number')}</label>
                                            <IntlTelInput
                                                style={{direction: 'ltr'}}
                                                pattern='[0-9]*'
                                                required
                                                onInput={(e) =>
                                                    (e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    ))
                                                }
                                                preferredCountries={['sd']}
                                                containerClassName='intl-tel-input'
                                                inputClassName='form-control'
                                                onPhoneNumberChange={(
                                                    status,
                                                    value,
                                                    countryData,
                                                    number,
                                                    id
                                                ) => {
                                                    $(".intl-tel-input input[type='tel']").attr(
                                                        'maxlength',
                                                        '10'
                                                    );
                                                    setPhoneNumber(number);
                                                    if (!phoneNumber) {
                                                        // $('.phone').show();
                                                        setErrorPhoneNumber(
                                                            'phone number must be required'
                                                        );
                                                    } else {
                                                        // $('.phone').hide();
                                                        setErrorPhoneNumber('');
                                                    }
                                                }}
                                            ></IntlTelInput>
                                            <div
                                                style={{marginTop: '6px'}}
                                                className='help-block with-errors phone error'
                                            >
                                                {errorPhoneNumber}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='400'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('referral')}</label>
                                            <input
                                                type='text'
                                                autoComplete='off'
                                                className='form-control'
                                                onInput={(e) => {
                                                    setCodeRevierd(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 mt-2 mb-2 label'>
                                        <input
                                            type='radio'
                                            name='type-payment'
                                            onChange={() => setCheckRadio('account')}
                                            value='account'
                                        />
                                        <label className='ml-1'> {t('account')} </label>
                                        <input
                                            className='ml-4'
                                            type='radio'
                                            defaultChecked
                                            name='type-payment'
                                            value='card'
                                            onChange={() => setCheckRadio('card')}
                                        />
                                        <label className='ml-1'> {t('card')} </label>
                                    </div>
                                    <div className='col-12 ml-3 error'>{cardTypeError}</div>
                                    {checkRadio === 'card' && (
                                        <>
                                            <div
                                                className='col-md-6 label'
                                                data-aos='fade-up'
                                                data-aos-delay='400'
                                            >
                                                <div className='form-group'>
                                                    <label htmlFor=''>{t('enter-card-receive-no')}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id=''
                                                        autoComplete='off'
                                                        name='card-receive'
                                                        placeholder='xxxx-xxxx-xxxx-xxxx'
                                                        required
                                                        maxLength='23'
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(
                                                                /[^0-9]/g,
                                                                ''
                                                            );
                                                            var foo = e.target.value.split('-').join('');
                                                            if (foo.length > 0) {
                                                                foo = foo
                                                                    .match(new RegExp('.{1,4}', 'g'))
                                                                    .join('-');
                                                            }
                                                            setCardReceive(e.target.value);
                                                            e.target.value = foo;
                                                        }}
                                                    />
                                                    <div
                                                        style={{marginTop: '6px'}}
                                                        className='help-block with-errors phone error text-center'
                                                    >
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {checkRadio === 'account' && (
                                        <>
                                            <div
                                                className='col-md-6 label'
                                                data-aos='fade-up'
                                                data-aos-delay='400'
                                            >
                                                <div className='form-group'>
                                                    <label htmlFor=''>{t('enter-account-receive-no')}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        autoComplete='off'
                                                        name='account-bank'
                                                        placeholder='xxxxxx'
                                                        required
                                                        minLength='6'
                                                        maxLength='10'
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(
                                                                /[^0-9]/g,
                                                                ''
                                                            );
                                                            var foo = e.target.value.split('-').join('');
                                                            if (foo.length > 0) {
                                                                foo = foo
                                                                    .match(new RegExp('.{1,4}', 'g'))
                                                                    .join('-');
                                                            }
                                                            setBankAccount(e.target.value);
                                                            e.target.value = foo;
                                                        }}
                                                    />
                                                    <div
                                                        style={{marginTop: '6px'}}
                                                        className='help-block with-errors phone error text-center'
                                                    >
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className='col-md-6 label'
                                                data-aos='fade-up'
                                                data-aos-delay='200'
                                            >
                                                <label htmlFor='bank-name'>{t('bank-name')}</label>
                                                <input
                                                    type='text'
                                                    name='bank-name'
                                                    id='bank-name'
                                                    className='form-control'
                                                    required
                                                    autoComplete='off'
                                                    minLength='6'
                                                    onInput={(e) => setBankName(e.target.value)}
                                                />
                                                <div
                                                    style={{marginTop: '6px'}}
                                                    className='help-block with-errors phone error text-center'
                                                >
                                                    {errorMessage}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div
                                        className='col-md-6 label'
                                        data-aos='fade-up'
                                        data-aos-delay='500'
                                    >
                                        <div className='form-group'>
                                            <label htmlFor=''>{t('enter-amount-usd')}</label>
                                            <input
                                                style={{
                                                    display: 'inline-block',
                                                    width: '100%',
                                                    marginRight: '10px',
                                                }}
                                                type='text'
                                                className='form-control'
                                                name='amount'
                                                placeholder=''
                                                maxLength='4'
                                                autoComplete='off'
                                                required
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );
                                                    setAmount(e.target.value);
                                                    if (e.target.value > 0) {
                                                        $('.dollar').removeClass('d-none');
                                                        if (e.target.value < 10) {
                                                            $('.fees').addClass('d-none');
                                                            $('.check').html(
                                                                'Payment amount must be at least 10 USD'
                                                            );
                                                            $('.check').removeClass('d-none');
                                                            setAmount(e.target.value);
                                                        } else if (e.target.value > 200) {
                                                            $('.check').html('Payment limit is $200.00');
                                                            $('.check').removeClass('d-none');
                                                            setFees(
                                                                e.target.value * 0.05 +
                                                                e.target.value * 0.037 +
                                                                1
                                                            );
                                                        } else {
                                                            $('.check').addClass('d-none');
                                                            if (e.target.value <= 10) {
                                                                setFees(
                                                                    e.target.value * 0.05 +
                                                                    e.target.value * 0.037 +
                                                                    0.5
                                                                );
                                                                $('.fees').removeClass('d-none');
                                                            } else if (e.target.value >= 10) {
                                                                setFees(
                                                                    e.target.value * 0.05 +
                                                                    e.target.value * 0.037 +
                                                                    1
                                                                );
                                                                $('.fees').removeClass('d-none');
                                                            }
                                                        }
                                                    } else {
                                                        $('.dollar').addClass('d-none');
                                                    }
                                                }}
                                            />
                                            <span className='text-center error dollarx d-none'></span>
                                            <p
                                                className='text-left fees d-none'
                                                style={{fontWeight: 'bolder', color: '#0d025b'}}
                                            >
                                                {t('fees-details')}
                                                {parseFloat(fees).toFixed(2)}{t('exchange-rate')}
                                                {priceDoller} {t('sdg')}
                                            </p>
                                            <div>
                                                <p
                                                    style={{marginLeft: '0px'}}
                                                    className='text-center error check d-none '
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 dollar d-none'>
                                        <label htmlFor=''>{t('total-amount-received-sdg')}</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            disabled
                                            value={(
                                                (parseFloat(amount) - parseFloat(fees)) *
                                                priceDoller
                                            ).toFixed(2)}
                                        />
                                        <div>
                                            <p
                                                style={{marginLeft: '0px'}}
                                                className='text-left fees d-none'
                                            >{` ${t('fees')} : ${fees.toFixed(2)} ${t('usd')}  / ${t('total-amount')} : ${(
                                                parseFloat(amount) - parseFloat(fees)
                                            ).toFixed(2)} ${t('usd')}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='error col-12 text-center mt-4'>
                                    {errorMessage}
                                </div>
                                <div className='col-md-12 pt-3'>
                                    <div className='form-group text-center'>
                                        {amount && (cardReceive || bankAccount) && (
                                            <>

                                                <>

                                                    <button type='submit' className='btn btn-common'>
                                                        {t('pay-with-paypal')}
                                                    </button>

                                                </>

                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <BottomFooter/>
            <div id='preloader'>
                <div className='loader' id='loader-1'></div>
            </div>
        </>
    );
}

export default CashOut;
