import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import axios from 'axios';
import {Link, useHistory} from 'react-router-dom';
import NavHeader from '../../ComHeader/js/NavHeader';
import FormTopup from './Topup/FormTopup';
import BottomFooter from '../../ComFooter/js/BottomFooter';
import FormBillPayment from './BillPayment/FormBillPayment';
import ModelInfoBillPayment from './BillPayment/ModelInfoBillPayment';
import ModelPayBillPayment from './BillPayment/ModelPayBillPayment';
import {BASE_URL} from '../../../utils';
import {errorMessageInfo} from '../../../utils/ErrorMessagesTransaction';
import {useTranslation} from 'react-i18next';

function Telecom() {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [status, setStatus] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [companyName, setCompanyName] = useState();
    const [numberStart, setNumberStart] = useState();
    const [phonePlaceHolder, setPhonePlaceHolder] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [payeeId, setPayeeId] = useState();
    useEffect(() => {
        $('#preloader').fadeOut(); // loding befor page is load
    }, []);
    useEffect(() => {
        if (localStorage.getItem('lang') === 'en') {
            $('html').attr('lang', 'en');
            $('body').css({direction: 'ltr'});
        } else if (localStorage.getItem('lang') === 'ar') {
            i18n.changeLanguage('ar');
            $('body').css({direction: 'rtl'});
            $('html').attr('lang', 'ar');
        }
    }, [i18n]);
    const gitBill = (e, serviceName, companyName) => {
        //get bill payment info
        e.preventDefault();
        $('#preloader').fadeIn(); // show loading
        const data = {
            phone_no: e.target.elements.phoneNumber.value,
        };
        axios
            .post(
                'https://middleware.enayapay.com/api/v2.1/telecom/' + e.target.payeeId.value + '/inquiry/',
                data,
                {
                    headers: {
                        'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
                        token: sessionStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((res) => {
                // response success
                setPhoneNumber(e.target.elements.phoneNumber.value);
                setStatus('success');
                setTotalAmount(res.data.totalAmount);
                if (status === 'failed') {
                    // status failed
                    $('.info').css({
                        display: 'none',
                    });
                }
                $('#preloader').fadeOut(2000);
                $('.showBillPaymentInfo').addClass('show').css({
                    display: 'block',
                });
                if (companyName === 'Sudani') {
                    setCompanyName(companyName);
                    setPayeeId('0010010006');
                    setNumberStart('[01]{2}[0-9]{8}');
                    setPhonePlaceHolder('0123456789');
                } else if (companyName === 'Zain') {
                    setCompanyName(companyName);
                    setPayeeId('0010010002');
                    setNumberStart('[09]{2}[0-9]{8}');
                    setPhonePlaceHolder('0912345678');
                } else {
                    setCompanyName(companyName);
                    setPayeeId('0010010004');
                    setNumberStart('[099,092]{3}[0-9]{7}');
                    setPhonePlaceHolder('0991234567');
                }
            })
            .catch((error) => {
                history.push({
                    pathname: '/wrong',
                    state: {
                        error: errorMessageInfo(error.response.data.code, t),
                    },
                });
            });
    };
    /*---------------------------------------------------------------- */
    const dimsssModelInfoBillPayment = () => {
        // dimsss model bill payment info
        $('.showBillPaymentInfo').removeClass('show').css({
            display: 'none',
        });
    };
    /*---------------------------------------------------------------- */
    const pushBillPayment = (e, serviceName, companyName) => {
        // dimsss model push bill payment
        $('.showBillPaymentInfo').removeClass('show').css({
            display: 'none',
        });
        $('.showBillPaymentPush').addClass('show').css({
            // show more bill pyament
            display: 'block',
        });
    };
    /*---------------------------------------------------------------- */
    const dimmssPush = (e, serviceName, companyName) => {
        // dimmss model push payment
        $('.showBillPaymentPush').removeClass('show').css({
            display: 'none',
        });
    };
    return (
        <>
            <NavHeader active='home'/>
            <section id='' className='section-padding pb-0'>
                <div className='container'>
                    <nav className='main-tabs'>
                        <div className='nav nav-tabs row' id='nav-tab' role='tablist'>
                            <a
                                className='nav-item col-6 nav-link active'
                                id='nav-topup-tab'
                                data-toggle='tab'
                                href='#nav-topup'
                                role='tab'
                                aria-controls='nav-topup'
                                aria-selected='true'
                            >
                                <div className='section-header text-center mb-0'>
                                    <h2
                                        className='section-title wow fadeInDown animated'
                                        data-wow-delay='0.3s'
                                        style={{visibility: 'visible', animationDelay: '0.3s'}}
                                    >
                                        {t('top-ups')}
                                    </h2>
                                </div>
                            </a>
                            <a
                                className='nav-item col-6 nav-link'
                                id='nav-bill-tab'
                                data-toggle='tab'
                                href='#nav-bill'
                                role='tab'
                                aria-controls='nav-bill'
                                aria-selected='false'
                            >
                                <div className='section-header text-center mb-0'>
                                    <h2
                                        className='section-title wow fadeInDown animated'
                                        data-wow-delay='0.3s'
                                        style={{visibility: 'visible', animationDelay: '0.3s'}}
                                    >
                                        {t('bill-payment')}
                                    </h2>
                                </div>
                            </a>
                        </div>
                    </nav>
                    <div className='row mt-3'>
                        <div className='tab-content col-12' id='nav-tabContent'>
                            <div
                                className='tab-pane fade show active'
                                id='nav-topup'
                                role='tabpanel'
                                aria-labelledby='nav-topup-tab'
                            >
                                <div className='row justify-content-center'>
                                    <nav className='topop'>
                                        <div
                                            className='nav row nav-tabs'
                                            id='nav-tab'
                                            role='tablist'
                                        >
                                            <a
                                                className='col-4 nav-item nav-link active'
                                                id='nav-zain-tab'
                                                data-toggle='tab'
                                                href='#nav-zain'
                                                role='tab'
                                                aria-controls='nav-zain'
                                                aria-selected='true'
                                            >
                                                <img
                                                    className='tab-img'
                                                    src='./images/zain.png'
                                                    alt=''
                                                />
                                            </a>
                                            <a
                                                className='col-4 nav-item nav-link'
                                                id='nav-mtn-tab'
                                                data-toggle='tab'
                                                href='#nav-mtn'
                                                role='tab'
                                                aria-controls='nav-mtn'
                                                aria-selected='false'
                                            >
                                                <img
                                                    className='tab-img'
                                                    src='./images/mtn.png'
                                                    alt=''
                                                />
                                            </a>
                                            <a
                                                className='col-4 nav-item nav-link'
                                                id='nav-sudani-tab'
                                                data-toggle='tab'
                                                href='#nav-sudani'
                                                role='tab'
                                                aria-controls='nav-sudani'
                                                aria-selected='false'
                                            >
                                                <img
                                                    className='tab-img'
                                                    src='./images/sudani.jpeg'
                                                    alt=''
                                                />
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                                <div className='row mt-5 pb-3'>
                                    <div className='tab-content col-12' id='nav-tabContent'>
                                        <div
                                            className='tab-pane fade show active'
                                            id='nav-zain'
                                            role='tabpanel'
                                            aria-labelledby='nav-zain-tab'
                                        >
                                            <div className='row justify-content-center'>
                                                <div className='col-md-8 services-item'>
                                                    <FormTopup
                                                        t={t}
                                                        nStart='09'
                                                        servicesName='Top Ups'
                                                        companyName={'Zain'}
                                                        messageInavlid={`${t('p-number-must-start')} 09`}
                                                        payeeId='0010010001'
                                                        numberPhoneHint={'0912345678'}
                                                        numberStart='[09]{2}[0-9]{8}'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='tab-pane fade'
                                            id='nav-mtn'
                                            role='tabpanel'
                                            aria-labelledby='nav-mtn-tab'
                                        >
                                            <div className='row justify-content-center'>
                                                <div className='col-md-8 services-item'>
                                                    <FormTopup
                                                        t={t}
                                                        nStart='099'
                                                        servicesName='Top Ups'
                                                        companyName={'MTN'}
                                                        messageInavlid={`${t('p-number-must-start')} 099 or 092`}
                                                        payeeId='0010010003'
                                                        numberPhoneHint={'0991234567'}
                                                        numberStart='[099,092]{3}[0-9]{7}'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='tab-pane fade'
                                            id='nav-sudani'
                                            role='tabpanel'
                                            aria-labelledby='nav-sudani-tab'
                                        >
                                            <div className='row justify-content-center'>
                                                <div className='col-md-8 services-item'>
                                                    <FormTopup
                                                        t={t}
                                                        nStart='01'
                                                        servicesName='Top Ups'
                                                        companyName={'Sudani'}
                                                        messageInavlid={`${t('p-number-must-start')} 01`}
                                                        payeeId='0010010005'
                                                        numberPhoneHint={'0123456789'}
                                                        numberStart='[01]{2}[0-9]{8}'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='tab-pane fade'
                                id='nav-bill'
                                role='tabpanel'
                                aria-labelledby='nav-bill-tab'
                            >
                                <div className='row justify-content-center'>
                                    <nav className='topop'>
                                        <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                                            <a
                                                className='nav-item nav-link active'
                                                id='nav-zain-tab'
                                                data-toggle='tab'
                                                href='#nav-zain-bill'
                                                role='tab'
                                                aria-controls='nav-zain-bill'
                                                aria-selected='true'
                                            >
                                                <img
                                                    className='tab-img'
                                                    src='./images/zain.png'
                                                    alt=''
                                                />
                                            </a>
                                            <a
                                                className='nav-item nav-link'
                                                id='nav-mtn-tab'
                                                data-toggle='tab'
                                                href='#nav-mtn-bill'
                                                role='tab'
                                                aria-controls='nav-mtn-bill'
                                                aria-selected='false'
                                            >
                                                <img
                                                    className='tab-img'
                                                    src='./images/mtn.png'
                                                    alt=''
                                                />
                                            </a>
                                            <a
                                                className='nav-item nav-link'
                                                id='nav-sudani-tab'
                                                data-toggle='tab'
                                                href='#nav-sudani-bill'
                                                role='tab'
                                                aria-controls='nav-sudani-bill'
                                                aria-selected='false'
                                            >
                                                <img
                                                    className='tab-img'
                                                    src='./images/sudani.jpeg'
                                                    alt=''
                                                />
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                                <div className='row mt-5'>
                                    <div className='tab-content col-12' id='nav-tabContent'>
                                        <div
                                            className='tab-pane fade show active'
                                            id='nav-zain-bill'
                                            role='tabpanel'
                                            aria-labelledby='nav-zain-tab'
                                        >
                                            <div className='row justify-content-center'>
                                                <div className='col-md-4 services-item'>
                                                    <FormBillPayment
                                                        t={t}
                                                        gitBill={(e) => gitBill(e, 'Bill Payment', 'Zain')}
                                                        servicesName='Bill Payment'
                                                        companyName={'Zain'}
                                                        payeeId='0010010002'
                                                        messageInavlid={`${t('p-number-must-start')} 09`}
                                                        numberPhoneHint={'0912345678'}
                                                        numberStart='[09]{2}[0-9]{8}'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='tab-pane fade'
                                            id='nav-mtn-bill'
                                            role='tabpanel'
                                            aria-labelledby='nav-mtn-tab'
                                        >
                                            <div className='row justify-content-center'>
                                                <div className='col-md-4 services-item'>
                                                    <FormBillPayment
                                                        t={t}
                                                        gitBill={(e) => gitBill(e, 'Bill Payment', 'MTN')}
                                                        servicesName='Bill Payment'
                                                        companyName={'MTN'}
                                                        payeeId='0010010004'
                                                        messageInavlid={`${t('p-number-must-start')} 099 or 092`}
                                                        numberPhoneHint={'0991234567'}
                                                        numberStart='[099,092]{3}[0-9]{7}'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='tab-pane fade'
                                            id='nav-sudani-bill'
                                            role='tabpanel'
                                            aria-labelledby='nav-sudani-tab'
                                        >
                                            <div className='row justify-content-center'>
                                                <div className='col-md-4 services-item'>
                                                    <FormBillPayment
                                                        t={t}
                                                        gitBill={(e) =>
                                                            gitBill(e, 'Bill Payment', 'Sudani')
                                                        }
                                                        servicesName='Bill Payment'
                                                        companyName={'Sudani'}
                                                        payeeId='0010010006'
                                                        messageInavlid={`${t('p-number-must-start')} 01`}
                                                        numberPhoneHint={'0123456789'}
                                                        numberStart='[01]{2}[0-9]{8}'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BottomFooter/>
            <Link to='#' className='back-to-top'>
                <i className='material-icons'>north</i>
            </Link>
            <div id='preloader'>
                <div className='loader' id='loader-1'></div>
            </div>
            <ModelInfoBillPayment
                t={t}
                status={status}
                totalAmount={totalAmount}
                companyName={companyName}
                pushBillPayment={(e) => {
                    pushBillPayment(e);
                }}
                dimsssModelInfoBillPayment={(e) => {
                    dimsssModelInfoBillPayment(e);
                }}
            />
            {totalAmount ? (
                <ModelPayBillPayment
                    t={t}
                    totalAmount={Math.round(totalAmount)}
                    phoneNumber={phoneNumber}
                    payeeId={payeeId}
                    dimmssPush={(e) => {
                        dimmssPush(e);
                    }}
                    companyName={companyName}
                    phonePlaceHolder={phonePlaceHolder}
                    numberStart={numberStart}
                />
            ) : (
                ''
            )}
        </>
    );
}

export default Telecom;
