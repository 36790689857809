export const errorMessageInfo = (code,t) => {
  let errorMessage = `${t('cant\'t-complete-transaction')}`;
  if (code === 1) {
    errorMessage = "Forgrt Prametr";
  }
  if (code === 10) {
    errorMessage = "Should select account number";
  }
  if (code === 11) {
    errorMessage = "Should change PVV";
  }
  if (code === 13) {
    errorMessage = "Select Bill";
  }
  if (code === 17) {
    errorMessage = `${t('p-info-error')}`;
  }
  if (code === 20) {
    errorMessage = `${t('p-code-not-found')}`;
  }
  if (code === 21) {
    errorMessage = `${t('account-exhausted')}`;
  }
  if (code === 22) {
    errorMessage = `${t('limit-exceeded')}`;
  }
  if (code === 40) {
    errorMessage = `${t('lost-card')}`;
  }
  if (code === 41) {
    errorMessage = `${t('stolen-card')}`;
  }
  if (code === 49) {
    errorMessage = `${t('ineligible-account')}`;
  }
  if (code === 50) {
    errorMessage = `${t('unauthorized-usage')}`;
  }
  if (code === 51) {
    errorMessage = `${t('expired-card')}`;
  }
  if (code === 52) {
    errorMessage = `${t('invalid-card')}`;
  }
  if (code === 53) {
    errorMessage = `${t('invalid-pin')}`;
  }
  if (code === 54) {
    errorMessage = `${t('system-error-unexpected')}`;
  }
  if (code === 55) {
    errorMessage = `${t('ineligible-transaction')}`;
  }
  if (code === 56) {
    errorMessage = `${t('ineligible-account')}`;
  }
  if (code === 57) {
    errorMessage = `${t('t-not-supported')}`;
  }
  if (code === 58) {
    errorMessage = `${t('restricted-card')}`;
  }
  if (code === 59) {
    errorMessage = `${t('insufficient-funds')}`;
  }
  if (code === 60) {
    errorMessage = `${t('uses-limit-exceeded')}`;
  }
  if (code === 61) {
    errorMessage = `${t('withdrawal-limit-exceeded')}`;
  }
  if (code === 62) {
    errorMessage = `${t('pin-limit-reached')}`;
  }
  if (code === 63) {
    errorMessage = `${t('limit-already-reached')}`;
  }
  if (code === 64) {
    errorMessage = `${t('credit-amount-limit')}`;
  }
  if (code === 65) {
    errorMessage = `${t('no-statement-info')}`;
  }
  if (code === 66) {
    errorMessage = `${t('s-not-available')}`;
  }
  if (code === 67) {
    errorMessage = `${t('invalid-amount')}`;
  }
  if (code === 68) {
    errorMessage = `${t('external-decline')}`;
  }
  if (code === 69) {
    errorMessage = `${t('no-sharing')}`;
  }
  if (code === 71) {
    errorMessage = `${t('contact-card-issuer')}`;
  }
  if (code === 72) {
    errorMessage = `${t('destination-not-available')}`;
  }
  if (code === 73) {
    errorMessage = `${t('routing-error')}`;
  }
  if (code === 74) {
    errorMessage = `${t('format-error')}`;
  }
  if (code === 75) {
    errorMessage = `${t('ex-special-condition')}`;
  }
  if (code === 80) {
    errorMessage = `${t('bad-cvv')}`;
  }
  if (code === 81) {
    errorMessage = `${t('bad-cvv-two')}`;
  }
  if (code === 82) {
    errorMessage = `${t('invalid-transaction')}`;
  }
  if (code === 83) {
    errorMessage = `${t('pin-imit-exceeded')}`;
  }
  if (code === 84) {
    errorMessage = `${t('bad-cavv')}`;
  }
  if (code === 85) {
    errorMessage = `${t('bad-arqc')}`;
  }
  if (code === 86) {
    errorMessage = `${t('server-up')}`;
  }
  if (code === 87) {
    errorMessage = `${t('none')}`;
  }
  if (code === 300) {
    errorMessage = `${t('biller-transaction')}`;
  }
  if (code === 301) {
    errorMessage = `${t('unresolved-transaction-biller-timeout')}`;
  }
  if (code === 302) {
    errorMessage = `${t('biller-destination-unavailable')}`;
  }
  if (code === 303) {
    errorMessage = `${t('payment-declined-invalid')}`;
  }
  if (code === 304) {
    errorMessage = `${t('payment-Declined-wrong -data')}`;
  }
  if (code === 305) {
    errorMessage = `${t('payment-declined-already-paid')}`;
  }
  if (code === 306) {
    errorMessage = `${t('payment-declined-invalid-amount')}`;
  }
  if (code === 412) {
    errorMessage = `${t('system-maintenance')}`;
  }
  if (code === 600) {
    errorMessage = `${t('INVALID IPIN BLOCK')}`;
  }
  if (code === 601) {
    errorMessage = `${t('invalid-palication-id')}`;
  }
  if (code === 602) {
    errorMessage = `${t('invalid-uuid')}`;
  }
  if (code === 603) {
    errorMessage = `${t('invalid-date-time')}`;
  }
  if (code === 604) {
    errorMessage = `${t('invalid-card-no')}`;
  }
  if (code === 605) {
    errorMessage = `${t('invalid-exp-date')}`;
  }
  if (code === 606) {
    errorMessage = `${t('invalid-original-time-date')}`;
  }
  if (code === 607) {
    errorMessage = `${t('invalid-original-guid')}`;
  }
  if (code === 608) {
    errorMessage = `${t('invalid-amount')}`;
  }
  if (code === 609) {
    errorMessage = `${t('invalid-to-card')}`;
  }
  if (code === 610) {
    errorMessage = `${t('invalid-transaction')}`;
  }
  if (code === 611) {
    errorMessage = `${t('formate-error')}`;
  }
  if (code === 612) {
    errorMessage = `${t('invalid-payee-id')}`;
  }
  if (code === 613) {
    errorMessage = `${t('duplicate-transaction')}`;
  }
  if (code === 614) {
    errorMessage = `${t('invalid-payment-info')}`;
  }
  if (code === 615) {
    errorMessage = `${t('cannot-reversed')}`;
  }
  if (code === 616) {
    errorMessage = `${t('invalid-account')}`;
  }
  if (code === 617) {
    errorMessage = `${t('invalid-mbr')}`;
  }
  if (code === 618) {
    errorMessage = `${t('invalid-account-type')}`;
  }
  if (code === 619) {
    errorMessage = `${t('invalid-transaction-currency')}`;
  }
  if (code === 620) {
    errorMessage = `${t('invalid-authentication-type')}`;
  }
  if (code === 621) {
    errorMessage = `${t('invalid-authentication')}`;
  }
  if (code === 622) {
    errorMessage = `${t('invalid-registration-type')}`;
  }
  if (code === 623) {
    errorMessage = `${t('invalid-user-name')}`;
  }
  if (code === 624) {
    errorMessage = `${t('invalid-entity-id')}`;
  }
  if (code === 625) {
    errorMessage = `${t('invalid-entity-type')}`;
  }
  if (code === 626) {
    errorMessage = `${t('invalid-entity-group')}`;
  }
  if (code === 627) {
    errorMessage = `${t('invalid-phone-no')}`;
  }
  if (code === 628) {
    errorMessage = `${t('invalid-email')}`;
  }
  if (code === 629) {
    errorMessage = `${t('invalid-institution-id')}`;
  }
  if (code === 630) {
    errorMessage = `${t('invalid-user-password')}`;
  }
  if (code === 631) {
    errorMessage = `${t('not-enrolled-before')}`;
  }
  if (code === 632) {
    errorMessage = `${t('cannot-generate-card')}`;
  }
  if (code === 633) {
    errorMessage = `${t('customer-already-exsists')}`;
  }
  if (code === 634) {
    errorMessage = `${t('invalid-card')}`;
  }
  if (code === 635) {
    errorMessage = `${t('cannot-use-card')}`;
  }
  if (code === 636) {
    errorMessage = `${t('invalid-security-question')}`;
  }
  if (code === 637) {
    errorMessage = `${t('invalid-security-question-answer')}`;
  }
  if (code === 638) {
    errorMessage = `${t('invalid-job')}`;
  }
  if (code === 639) {
    errorMessage = `${t('invalid-job')}`;
  }
  if (code === 640) {
    errorMessage = `${t('invalid-date-birth')}`;
  }
  if (code === 641) {
    errorMessage = `${t('invalid-bank-acount-numbe')}`;
  }
  if (code === 642) {
    errorMessage = `${t('invalid-bank-acount-type')}`;
  }
  if (code === 643) {
    errorMessage = `${t('invalid-bank-branch-id')}`;
  }
  if (code === 644) {
    errorMessage = `${t('invalid-bank-id')}`;
  }
  if (code === 645) {
    errorMessage = `${t('invalid-full-name')}`;
  }
  if (code === 646) {
    errorMessage = `${t('invalid-customer-number')}`;
  }
  if (code === 647) {
    errorMessage = `${t('invalid-customer-id-type')}`;
  }
  if (code === 648) {
    errorMessage = `${t('invalid-admin-user-name')}`;
  }
  if (code === 649) {
    errorMessage = `${t('Invalid Voucher Number')}`;
  }
  if (code === 651) {
    errorMessage = `${t('unauthorized-payee-id')}`;
  }
  if (code === 652) {
    errorMessage = `${t('unauthorized-authentication-type')}`;
  }
  if (code === 653) {
    errorMessage = `${t('unauthorized-registration-type')}`;
  }
  if (code === 654) {
    errorMessage = `${t('unauthorized-transaction-currency')}`;
  }
  if (code === 655) {
    errorMessage = `${t('unauthorized-transaction')}`;
  }
  if (code === 656) {
    errorMessage = `${t('unauthorized-card-prefix')}`;
  }
  if (code === 657) {
    errorMessage = `${t('unauthorized-amount')}`;
  }
  if (code === 658) {
    errorMessage = `${t('unauthorized-pan-category')}`;
  }
  if (code === 659) {
    errorMessage = `${t('unauthorized-financial-institution-id')}`;
  }
  if (code === 660) {
    errorMessage = `${t('approved-notification-customer')}`;
  }
  if (code === 661) {
    errorMessage = `${t('invalid-service-info')}`;
  }
  if (code === 662) {
    errorMessage = `${t('invalid-service-provider-id')}`;
  }
  if (code === 663) {
    errorMessage = `${t('unauthorized-service-provider-id')}`;
  }
  if (code === 664) {
    errorMessage = `${t('wrong-expiration-date')}`;
  }
  if (code === 665) {
    errorMessage = `${t('registered-card-already-updated')}`;
  }
  if (code === 668) {
    errorMessage = `${t('application-not-available-mobile')}`;
  }
  if (code === 669) {
    errorMessage = `${t('payee-not-available-mobile')}`;
  }
  if (code === 670) {
    errorMessage = `${t('service-provider-not-available-mobile')}`;
  }
  if (code === 671) {
    errorMessage = `${t('wrong-mobile-number')}`;
  }
  if (code === 672) {
    errorMessage = `${t('time-transactions-exceeded')}`;
  }
  if (code === 673) {
    errorMessage = `${t('invalid-last-four-digits')}`;
  }
  if (code === 674) {
    errorMessage = `${t('dynamic-fee-greater-transaction-amount')}`;
  }
  if (code === 678) {
    errorMessage = `${t('original-transaction-not-found')}`;
  }
  if (code === 679) {
    errorMessage = `${t('original-transaction-cnnot-refunded')}`;
  }
  if (code === 680) {
    errorMessage = `${t('original-transaction-refunded')}`;
  }
  if (code === 681) {
    errorMessage = `${t('channel-id-not-found')}`;
  }
  if (code === 682) {
    errorMessage = `${t('transaction-initiated-success')}`;
  }
  if (code === 683) {
    errorMessage = `${t('waiting-customer-authenticating-completion')}`;
  }
  if (code === 684) {
    errorMessage = `${t('no-available-card-application')}`;
  }
  if (code === 690) {
    errorMessage = `${t('payment-total-transaction-amount')}`;
  }
  if (code === 691) {
    errorMessage = `${t('invalid-payment-details-limit-exceeded')}`;
  }
  if (code === 692) {
    errorMessage = `${t('invalid-account-details')}`;
  }
  if (code === 693) {
    errorMessage = `${t('invalid-amount-payment-details')}`;
  }
  if (code === 694) {
    errorMessage = `${t('invalid-orig-uuid')}`;
  }
  if (code === 695) {
    errorMessage = `${t('invalid-otp-block')}`;
  }
  if (code === 696) {
    errorMessage = `${t('system-error')}`;
  }
  if (code === 706) {
    errorMessage = `${t('hermes-not-nitialized')}`;
  }
  if (code === 801) {
    errorMessage = `${t('fee-amount-less-min-fee')}`;
  }
  if (code === 802) {
    errorMessage = `${t('fee-amount-greater-tahn-max-fee')}`;
  }
  if (code === 803) {
    errorMessage = `${t('no-configuration-external-application')}`;
  }
  if (code === 804) {
    errorMessage = `${t('invalid-dynamic-fee-amount')}`;
  }
  if (code === 805) {
    errorMessage = `${t('dynamic-fee-greater-than-amount')}`;
  }
  if (code === 820) {
    errorMessage = `${t('invalid-id-no')}`;
  }
  if (code === 821) {
    errorMessage = `${t('invalid-id-type')}`;
  }
  if (code === 822) {
    errorMessage = `${t('invalid-merchant-name')}`;
  }
  if (code === 823) {
    errorMessage = `${t('invalid-merchant-city')}`;
  }
  if (code === 824) {
    errorMessage = `${t('invalid-merchant-mobile-no')}`;
  }
  if (code === 825) {
    errorMessage = `${t('invalid-merchant-account-type')}`;
  }
  if (code === 826) {
    errorMessage = `${t('invalid-merchant-account-reference')}`;
  }
  if (code === 827) {
    errorMessage = `${t('invalid-merchant-id')}`;
  }
  if (code === 852) {
    errorMessage = `${t('system-failed-check-duplicates')}`;
  }
  if (code === 903) {
    errorMessage = `${t('no-otp-foun-validated')}`;
  }
  if (code === 905) {
    errorMessage = `${t('otp-operation-faile')}`;
  }
  if (code === 923) {
    errorMessage = `${t('could-not-send-otp')}`;
  }
  if (code === 924) {
    errorMessage = `${t('otp-expired')}`;
  }
  if (code === 935) {
    errorMessage = `${t('otp-limit-exceeded')}`;
  }
  if (code === 936) {
    errorMessage = `${t('invalid-otp')}`;
  }
  if (code === 999) {
    errorMessage = `${t('timeout-response-code')}`;
  }
  return errorMessage;
};
