import axios from 'axios';
import { BASE_URL } from '.';
let ratePriceDoller = 0
export const getRatePriceDoller  = () => { //get rate dollar today
  axios.get(BASE_URL()+ 'rate/', '', {
    mode: "cors",
    headers: {
      'x-api-key': '249b847b2fd6d6be9959010a794cc78012a9dd73',
      'token': sessionStorage.getItem('token'),
      'Content-Type': 'application/json'
    },
  }).then((res) => {
    ratePriceDoller = res.data.rate
  })
  return ratePriceDoller
}