import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import '../css/main.css'

function BottomFooter() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  return (
    <footer id="footer" className="pt-4">
      <div id="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright-content">
                <p> {t('copyright')} {t('reserved')} © 2021 <a  rel="noreferrer noopener" href="https://www.enayapay.com" target="_blank"> Enayapay </a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default BottomFooter
