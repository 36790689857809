import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import NavHeader from "../../ComHeader/js/NavHeader";
import BottomFooter from "../../ComFooter/js/BottomFooter";
import $ from "jquery";
import axios from "axios";
import "react-intl-tel-input/dist/main.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/media.css";
import { BASE_URL } from "../../../utils";
import { useTranslation } from "react-i18next";

function NewPassword() {
  useEffect(() => {
    $("#preloader").fadeOut(); // loading befor page is load
    AOS.init(); // initial aos animation libaray
  }, []);
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (localStorage.getItem('lang') === 'en') {
      $('html').attr('lang', 'en');
      $('body').css({ direction: 'ltr' });
    } else if (localStorage.getItem('lang') === 'ar') {
      i18n.changeLanguage('ar');
      $('body').css({ direction: 'rtl' });
      $('html').attr('lang', 'ar');
    }
  }, [i18n]);
  // send data form login to api
  const handleSubmitSingIn = (e) => {
    console.log(location.state.otp);
    e.preventDefault();
    $("#preloader").fadeIn(); // show loading
    const data = {
      user_phone: location.state.phone_number,
      user_otp: location.state.otp,
      user_password: password,
    };
    axios
      .post("https://middleware.enayapay.com/api/v2.1/user/rest/password/", data, {
        headers: {
          "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // status success
          sessionStorage.setItem("EnayaPay", "UserLogin");
          sessionStorage.setItem("token", res.data.token);
          history.push({
            pathname: "/home",
            state: {
              // phone_number: phoneNumber.slice(1).replace(/[^A-Z0-9]/gi, ""),
            },
          });
        } else if (res.status === 203) {
          // error messages wrong otp
          setErrorMessage(`${t('wrong-otp-number')}`);
          $("#preloader").fadeOut();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          // status failed  bad reguest
          setErrorMessage(error.response.data.detials);
          $("#preloader").fadeOut();
        } else if (error.response.status === 401) {
          // status failed Unauthorized partiner
          setErrorMessage(`${t('unauthorized-partiner')}`);
          $("#preloader").fadeOut();
        } else if (error.response.status === 404) {
          // status failed user not registerd
          $("#preloader").fadeOut();
          setErrorMessage(`${t('p-number-not-registered')}`);
        } else {
          $("#preloader").fadeOut();
          setErrorMessage(`${t('can’t-complete-singin')}`);
        }
      });
  };
  return (
    <>
      <NavHeader active="home" />
      <section className="section-padding form-log pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-xs-12">
              <div
                className="services-item form wow  fadeInRight"
                data-wow-delay="0.3s"
              >
                <div className="row justify-content-center">
                  <div className="col-md-5 bg-warning d-none d-md-block">
                    <div className="p-5 text-center">
                      <img
                        className="w-100"
                        data-aos="fade-up"
                        src="/images/Login.svg"
                        alt="images login in icon"
                      />
                      <h4
                        className="text-white f-28 mt-5"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        {t('imagine-it')}
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className=" p-3">
                      <div className="mt-3 mb-3">
                        <p
                          className="text-right invisible"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          {t('don\'t-account')}
                          <Link to="/singup" className="text-warning">
                            {t('signup')}
                          </Link>
                        </p>
                      </div>
                      <div className="">
                        <h1 className="text-sub mb-0" data-aos="fade-up">
                          {t('new-password')}
                        </h1>
                        <p
                          className="invisible"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                         {t('login-account')}
                        </p>
                      </div>
                      <form
                        className="mt-3"
                        onSubmit={(e) => handleSubmitSingIn(e)}
                      >
                        <div className="row">
                          <div
                            className="col-md-7 m-auto"
                            style={{ position: "relative", zIndex: "9999" }}
                          ></div>
                          <div
                            className="col-md-7 m-auto"
                            data-aos="fade-up"
                            data-aos-delay="300"
                          >
                            <label htmlFor="last-name">
                              Enter Your New Password
                            </label>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder=""
                              required
                              minLength="8"
                              onInput={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="col-md-12 mt-1 mt-md-3 text-center">
                            <div className="help-block with-errors error text-center">
                              {errorMessage}
                            </div>
                            <button
                              className="btn btn-common submit mpt-4"
                              type="submit"
                            >
                              {t('reset')}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>
    </>
  );
}

export default NewPassword;
