import axios from "axios";
import { BASE_URL } from ".";
let publicKey = 0;
export const getPublicKey = () => {
  //get public key
  axios
    .get(BASE_URL() + "publickey/", {
      headers: {
        "x-api-key": "249b847b2fd6d6be9959010a794cc78012a9dd73",
        token: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      publicKey = res.data.pubKeyValue;
    });
  return publicKey;
};
